/*!
	HEADER
	===============================================
*/

.header
	padding: 20px 0;
	position: relative;
	z-index: 15;
	@media phone
		padding: 25px 0 0;

	&.is-sticky
		position: absolute;
		top: 0;
		left: 0;
		z-index: 15;

	&-box
		position: relative;

	&-item
		position: relative;
		z-index: 10;

	.bSocials
		margin-left: 20px;

	.bMainMenu
		position: absolute;
		top: 100%;
		left: 0;
		margin-top: 20px;
		@media phone
			position: absolute;
			top: -30px;
			left: auto;
			right: -100%;
			height: 100vh;
			max-width: 334px;
			width: 100%;
			z-index: 8;
			opacity: 0;
			background-color: #fff;
			margin-top: 0;
			overflow-y: auto;

		&.is-active
			right: -20px;
			opacity: 1;


.bHeaderLogo
	padding: 8px 12px;
	background-color: rgba(#fff,0);
	border-radius: 8px;
	transit();
	@media phone
		border-radius: 50px;

	&.is-active
		background-color: #fff;

	img
		max-height: 60px;
		@media phone
			max-height: 50px;


.bFormSearch
	background-color: #fff;
	border-radius: 8px;
	max-width: 282px;
	position: relative;
	transit();
	&:hover
		box-shadow: 0px 4px 10px rgba(#D2DEEC, 0.5);

	&-input
		height: 36px;
		padding: 0 40px 0 16px;
		font-size: 12px;
		background-color: #fff;
		border-radius: 8px;

	.error-message
		display: none;

	&-btn
		position: absolute;
		right: 5px;
		top: 5px;
		size(26px,26px);
		border-radius: 8px;
		background-color: $colorAccent;
		fill: #fff;

		.ico
			size(12px,12px);

	&.is-process &-btn
		position: absolute;


.autocompleteResult
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 100%;
	left: 0;
	margin-top: -5px;
	width: 100%;
	padding: 15px 5px 5px;
	background-color: #fff;
	border-radius: 0 0 8px 8px;
	font-size: 12px;
	line-height: round(16/12,2);
	color: #96A8BE;

	&:before
		pseudo();
		left: 5px;
		right: 5px;
		height: 1px;
		background-color: #96A8BE;
		top: 5px;
		left: 0;

	&-item
		padding: 5px 16px;
		cursor: pointer;

		mark
			color: $colorText;
			background: none;


.bTogglerMenu
	size(35px,35px);
	background-color: #fff;
	border-radius: 6px;
	fill: $colorAccent;

	.ico
		size(22px,22px);


.bMainMenu
	display: block;
	background-color: #fff;
	border-radius: 40px;
	padding: 35px 0;
	overflow: hidden;
	transit();
	@media phone
		border-radius: 0;
		padding: 110px 40px 40px;

	&-item
		&:not(:last-child)
			margin-bottom: 30px;
			@media phone
				margin-bottom: 60px;

	&-link
		fill: #96A8BE;

		span
			// display: none;
			display: inline-block;
			overflow: hidden;
			max-width: 0;
			opacity: 0;
			white-space: nowrap;
			transit();
			@media phone
				// display: inline-block;
				max-width: none;
				opacity: 1;

		&:hover,
		&.is-active
			fill: $colorAccent;
			color: $colorAccent;

	.ui-icon
		size(22px,22px);
		margin: 0 18px;

	&:hover &-link span
		max-width: none;
		opacity: 1;
		// display: inline-block;
		padding-right: 18px;
