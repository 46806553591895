/*!
 * --- PAGE Posts -------------------------------
*/
.bPost
	font-family: $fontKazimir;
	// max-width: 1145px;
	background-color: #fff;
	border-radius: 20px;
	padding: 22px 32px 72px;
	@media phone
		border-radius: 10px;
		padding: 16px 16px 20px;

	&-image
		position: relative;
		margin-bottom: 30px;

	&-backLink,
	&-shareLink
		position: absolute;
		top: 20px;
		z-index: 5;
		@media phone
			top: 15px;

	&-backLink
		left: 30px;
		@media phone
			left: 15px;

	&-shareLink
		right: 30px;
		@media phone
			right: 15px;

	&-pic
		height: 365px;
		border-radius: 20px;
		overflow: hidden;
		@media pad
			height: 265px;
		@media phone
			border-radius: 10px;
			height: 120px;

	&-header
		margin-bottom: 30px;
		color: $colorAccent;
		font-size: 11px;
		line-height: round(12/11,2);

	&-title
		color: $colorText;
		font-weight: 500;
		font-size: 25px;
		line-height: round(30/25,2);

	&-theme
		margin-bottom: 15px;

	&-date
		margin-top: 15px;

	&-txt
		font-size: 22px;
		font-weight: 300;
		line-height: round(25/22,2);
		@media phone
			font-size: 16px;

		p:not(:last-child)
			margin-bottom: 30px;
			@media phone
				margin-bottom: 15px;


.bPostGraph
	max-width: 800px;
	margin: auto;

	&:not(:last-child)
		margin-bottom: 30px;
		@media phone
			margin-bottom: 15px;

	&-title
		color: $colorAccent;
		font-weight: 500;
		margin-bottom: 30px;
		font-size: 20px;
		@media phone
			font-size: 16px;
			margin-bottom: 15px;

	&-month
		border-radius: 6px;
		background-color: #F4F7FE;
		fill: #A3AED0;
		color: #A3AED0;
		font-family: $fontDM;
		font-weight: 500;
		font-size: 12px;
		padding: 10px 24px;
		@media phone
			font-size: 8px;
			padding: 6px 15px;

		.ui-icon
			size(16px,16px);
			margin-right: 6px;
			@media phone
				size(10px,10px);
				margin-right: 4px;

	&-number
		font-family: $fontDM;
		font-weight: 500;
		font-size: 12px;
		color: #A3AED0;
		line-height: round(21/12,2);
		padding-left: 2px;
		@media phone
			font-size: 10px;

		&:not(:first-child)
			margin-top: 23px;
			@media phone
				margin-top: 10px;

		p:not(:last-child)
			margin-bottom: -4px;

		strong
			font-weight: bold;
			font-size: 30px;
			line-height: round(38/30,2);
			color: #2B3674;
			@media phone
				font-size: 22px;

		i
			font-style: normal;
			color: #05CD99;
			font-size: 10px;
			font-weight: bold;
			margin-left: 10px;
			@media phone
				display: block;

	&-row
		max-width: 620px;

		canvas
			height: auto;
			min-height: 160px;
			@media phone
				min-height: auto;

	&-canvas
		max-width: 648px;

		canvas
			max-height: 245px;
