/*!
 --- UI -----------------------------------------
*/

body
	font-family: $fontHelv;
	color: $colorText;
	background: #F3F8FE;


.container
	width: 100%;
	max-width: $container;
	padding-left: $containerPadding;
	padding-right: $containerPadding;
	margin: 0 auto;


.link
	transit();

	&:hover
		opacity: 0.6;


.is-process
	button[type="submit"]
		position: relative;

		&:before
			pseudo();
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(#fff,0.75);
			border-radius: 10px;
			z-index: 10;

		&:after
			pseudo();
			width: 32px;
			height: 32px;
			border-radius: 100%;
			top: 50%;
			left: 50%;
			margin-top: -16px;
			margin-left: -16px;
			border-width: 5px;
			border-color: #111827 #E5E7EB;
			border-style: solid;
			animation: process-rotate 1s ease-in-out infinite forwards;
			z-index: 15;


@keyframes process-rotate
	from
		transform: rotate(0deg);
	to
		transform: rotate(360deg);

@import 'framework';


/*!
	--- BASIC ---------------------------
*/
.ui-icon
	line-height: 1;
	size(16px,16px);
	flex: none;
	bg(center,contain);

	.ico
		size(100%,100%);


.ui-pic-bg
	position: absolute;
	size(100%,100%);
	top: 0;
	left: 0;

	img
		size(100%,100%);
		object-fit: cover;


.ui-pic-cover
	img
		size(100%,100%);
		object-fit: cover;


.ui-pic-contain
	img
		size(100%,100%);
		object-fit: contain;


.ui-tab
	display: none;
	opacity: 0;

	&.is-active
		display: block;
		animation: tab-show 1s linear 0.01s 1 forwards;

@keyframes tab-show
	from
		opacity: 0;
	to
		opacity: 1;


.ui-iframe-box
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;
	width: 100%;

	iframe
		border: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;


.ui-ul-clear
	padding: 0;
	margin: 0;
	list-style-type: none;


.ui-burger
	size(32px,24px);
	position: relative;

	&:before,
	&:after
		pseudo();

	&:before,
	&:after,
	span
		width: 100%;
		height: 4px;
		left: 0;
		background: red;
		border-radius: 10px;
		transit();

	&:before
		top: 0;

	&:after
		top: 100%;
		margin-top: -4px;

	span
		top: 50%;
		margin-top: 0;

	&.is-active
		span
			opacity: 0;

		&:before,
		&:after
			top: 50%;
			margin-top: -2px;

		&:before
			transform: rotate(45deg);

		&:after
			transform: rotate(-45deg);


/*!
	--- FORM ----------------------------
*/
.ui-fg
	position: relative;
	margin-bottom: 30px;
	@media phone
		margin-bottom: 15px;

	&.is-mb-0
		margin-bottom: 0;

	.error-message
		position: absolute;
		bottom: 3px;
		right: 5px;
		font-size: 0.8em;
		color: $colorError;


.ui-btn
	height: 56px;
	font-size: 17px;
	font-weight: 500;
	background-color: $colorAccent;
	color: #fff;
	border-radius: 100px;
	padding: 0 42px;
	// line-height: round(34/17,2);
	@media pad
		padding: 0 42px;

	&:hover
		box-shadow: 0px 6px 20px #A7BED9;

	&.is-min-w
		min-width: 225px;
		@media phone
			min-width: auto;

	&.is-thin
		height: 40px;
		font-size: 14px;
		font-weight: 500;
		line-height: round(14/16,2);

	&.is-white
		background-color: #fff;
		color: $colorAccent;
		box-shadow: 0px 4px 10px rgba(#D2DEEC, 0.5);

		&:hover
			background-color: $colorAccent;
			color: #fff;

	&.is-gray
		background-color: #96A8BE;
		color: #fff;

		&.is-ico
			fill: #fff;

		&:hover
			box-shadow: 0px 6px 20px #A7BED9;
			background-color: $colorAccent;
			color: #fff;

	&.is-ico
		padding: 0 20px;
		fill: $colorAccent;

		.ui-icon
			size(27px,21px);
			margin-right: 15px;

	&.is-form
		height: 36px;
		border-radius: 10px;
		padding: 0 20px;
		font-size: 14px;
		font-weight: 600;

	&.is-file
		height: 36px;
		border-radius: 10px;
		padding: 0 15px 0 20px;

		button
			flex: none;
			margin-left: 25px;
			size(16px,16px);
			border-radius: 50px;
			fill: #fff;

			.ico
				size(100%,100%);


.ui-btn-round
	border-radius: 100px;
	size(50px,50px);
	background-color: #fff;
	box-shadow: 0px 1px 3px rgba(#717171, 0.25);
	fill: $colorAccent;
	@media phone
		size(35px,35px);

	.ico
		size(25px,25px);
		@media phone
			size(18px,18px);


.download-link
	display: inline-flex;
	align-items: center;
	background-color: #96A8BE;
	color: #fff;
	padding: 12px 20px 10px;
	font-size: 16px;
	font-weight: 500;
	line-height: round(18/16,2);
	border-radius: 100px;
	@media phone
		width: 100%;
		max-width: 420px;

	&:before
		content: '';
		display: inline-block;
		flex: none;
		size(27px,21px);
		margin-right: 15px;
		bg(center,contain);
		background-image: url(../icons/cloud-download.svg);

	&:hover
		box-shadow: 0px 6px 20px #A7BED9;
		background-color: $colorAccent;
		color: #fff;


.ui-label
	font-weight: 300;
	margin-bottom: 15px;


.ui-input
	&::placeholder
		color: rgba(#273443, 0.4);


.ui-input-form
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid #96A8BE;
	height: 40px;
	padding: 0 16px;

	&.is-ta
		height: auto;
		padding: 16px;


.ui-widget
	background-color: #fff;
	border-radius: 10px;
	padding: 12px 16px;
	transit();
	@media phone
		padding: 8px 10px;
		border-radius: 5px;

	&-pic
		flex: none;
		size(58px,58px);
		overflow: hidden;
		@media phone
			size(36px,36px);

		img
			size(100%,100%);
			object-fit: contain;

		&.is-round
			border-radius: 100px;

			img
				object-fit: cover;

	&-body
		padding-left: 26px;
		color: #96A8BE;
		line-height: round(18/16,2);
		@media tablet
			padding-left: 16px;
		@media phone
			font-size: 10px;
			line-height: round(11/10,2);

		p:not(:last-child)
			margin-bottom: 5px;
			@media phone
				margin-bottom: 2px;

		strong
			font-family: $fontMont;
			color: $colorAccent;
			font-size: 22px;
			font-weight: bold;
			line-height: round(24/22,2);
			@media phone
				font-size: 14px;

	&:hover
		box-shadow: 0px 4px 10px rgba(#D2DEEC, 0.5);
