/*!
	--- BLOCKS ------------------------------------
*/

.js-gsap-canvas
	opacity: 0;

.is-main-menu-open .main
	&:before
		pseudo();
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(#999FA9,0.4);
		z-index: 10;
		transit();


.bSlPagination
	.bullet
		display: block;
		size(14px,14px);
		border: 1px solid $colorAccent;
		border-radius: 50px;
		@media phone
			size(12px,12px);

		&.is-active
			background-color: $colorAccent;

		&:not(:last-child)
			margin-right: 14px;
			@media phone
				margin-right: 12px;


.bSection
	&:not(:last-child)
		margin-bottom: 30px;

	&.is-w-grid
		&:not(:last-child)
			margin-bottom: 0;

	&-box
		max-width: 1204px;


.bSectionTitle
	color: $colorAccent;
	font-size: 25px;
	line-height: round(30/25,2);
	font-weight: 500;
	fill: $colorAccent;
	margin-bottom: 30px;
	@media phone
		font-size: 20px;

	.ui-icon
		size(20px,20px);
		margin-left: 15px;


.bCardGrid
	&-col
		padding-bottom: 30px;


.bCard
	background-color: #fff;
	border-radius: 10px;
	padding: 20px 30px 28px;
	height: 100%;
	@media pad
		padding: 10px 10px 18px;


.bCardLinkArrow
	flex: none;
	size(26px,26px);
	background-color: #E9F3FF;
	box-shadow: 0px 1px 2px rgba(#717171, 0.25);
	border-radius: 100px;
	fill: $colorAccent;

	.ico
		size(14px,10px);


.bTag
	color: $colorAccent;
	background-color: #E9F3FF;
	font-weight: 500;
	font-size: 12px;
	border-radius: 50px;
	min-width: 118px;
	padding: 7px 5px;
	@media phone
		min-width: 95px;
		padding: 6px 5px;
		font-size: 10px;

	&:not(:last-child)
		margin-right: 15px;
		@media phone
			margin-right: 12px;


.bSocials
	&-item
		&:not(:last-child)
			margin-right: 10px;

	&-link
		size(36px,36px);
		border-radius: 50px;
		background-color: #fff;
		box-shadow: 0px 1px 3px rgba(#717171, 0.25);

		&:hover
			box-shadow: 0px 4px 10px #D2DEEC;

	.ui-icon
		size(21px,21px);


.bFormFunctions
	margin-right: 30px;

	&-item
		size(22px,22px);
		fill: #B3B9C5;

		&:not(:last-child)
			margin-right: 15px;


.bGraphHorizontal
	&-item
		&:not(:last-child)
			margin-bottom: 10px;


.bGraphVertical
	height: 100%;
	max-height: 195px;


.bGraphLines
	height: 100%;


.bGraphLine
	font-family: $fontMont;
	font-size: 13px;
	line-height: round(18/13,2);
	color: $colorTitle;

	&-top
		margin-bottom: 4px;

	&-val
		font-weight: bold;

	&-scale
		height: 9px;
		background-color: #E9F3FF;
		border-radius: 30px;
		position: relative;

		span
			display: block;
			background-color: #00CB00;
			border-radius: 30px;
			height: 100%;
			width: 0;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;


.bDayNumber
	font-size: 20px;
	line-height: 1.2;
	color: $colorTitle;
	@media pad
		font-size: 13px;

	&-val
		color: $colorAccent;
		font-family: $fontMont;
		font-size: 70px;
		font-weight: bold;
		margin-right: 20px;
		@media pad
			font-size: 44px;


.bPage
	margin-bottom: 60px;
	@media phone
		margin-top: 22px;


.bPageTitle
	font-size: 25px;
	line-height: round(30/25,2);
	font-weight: 500;
	margin-bottom: 30px;
	@media phone
		font-size: 20px;


.bTagsGroup
	margin-bottom: 5px;
	font-size: 14px;
	font-weight: 500;
	margin-left: -15px;
	margin-right: -15px;
	@media phone
		font-size: 18px;
		margin-left: -8px;
		margin-right: -8px;

	&-link
		background-color: #fff;
		color: $colorAccent;
		min-width: 142px;
		height: 30px;
		border-radius: 50px;
		padding: 0 14px;
		white-space: nowrap;
		margin-bottom: 25px;
		@media phone
			min-width: auto;
			width: 100%;
			height: 40px;

		&.is-active
			background-color: $colorAccent;
			color: #fff;

	&-item
		padding-right: 15px;
		padding-left: 15px;
		@media phone
			padding-right: 8px;
			padding-left: 8px;
			min-width: 50%;

	&.is-bg
		background-color: #fff;
		border-radius: 100px;
		padding: 0 4px;
		font-size: 16px;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 30px;
		@media phone
			background: none;
			padding: 0;
			margin-left: -8px;
			margin-right: -8px;
			margin-bottom: 5px;

	&.is-bg &
		&-item
			padding: 0;
			@media phone
				padding-right: 8px;
				padding-left: 8px;

			&:not(:last-child)
				margin-right: 22px;
				@media phone
					margin-right: 0;

		&-link
			margin-top: 5px;
			margin-bottom: 5px;
			@media phone
				margin-top: 0;
				margin-bottom: 25px;


.bLoadMore
	&.is-posts
		margin-top: -55px;

	.ui-btn
		border-radius: 10px;
		height: 50px;


.bPagination
	font-size: 18px;
	font-family: $fontRoboto;

	&-item
		display: flex;
		align-items: center;
		justify-content: center;
		flex: none;
		size(42px,42px);
		border-radius: 4px;
		margin: 0 6px;

		&.is-current
			background-color: $colorAccent;
			color: #fff;
			font-weight: bold;

		&.is-dots
			width: auto;
			margin: 0 26px;

		&.is-prev,
		&.is-next
			fill: $colorText;

			.ico
				size(10px,10px);
