/*!
 * --- PARTS ------------------------------------
*/
.bFooterMsg
	position: relative;
	z-index: 5;

	&-body
		background-color: #E9F3FF;
		border-radius: 15px;
		max-width: 1058px;
		padding: 25px 0;
		// padding-left: 26.5%;
		// padding-right: 4%;
		padding-right: 74px;
		margin-top: 30px;
		margin-bottom: 30px;
		position: relative;
		bg(center,cover);
		background-image: url(../images/bg-msg-full.png);
		@media tablet
			padding-right: 48px;
		@media pad
			background: none;
			padding: 0 0 44px;
			margin: 0;

		&:before
			pseudo();
			top: -30px;
			bottom: -30px;
			left: 2.5%;
			width: 30%;
			bg(center,contain);
			background-image: url(../images/bg-msg.png);
			@media pad
				display: none;

	&-middle
		margin-right: 50px;
		@media pad
			margin-right: 0;
			margin-bottom: 30px;

	&-title
		font-weight: bold;
		font-size: 18px;
		line-height: round(22/18,2);

		b
			color: $colorAccent;

	&-txt
		line-height: round(18/16,2);
		margin-top: 5px;
