//- COLORS
$colorText = #273443;
$colorTitle = #4E5D78;
$colorAccent = #27A9FB;

$colorError = #EF4444;


//- FONT
$font = -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$fontHelv = 'HelveticaNeueCyr', $font;
$fontMont = 'Montserrat', $font;
$fontSF = 'SF Pro Display', $font;
$fontRoboto = 'Roboto', $font;
$fontKazimir = 'Kazimir', $font;
$fontDM = 'DM Sans', $font;


//- VARS
$containerPadding = 20px;
$container = 1380px + 40;
$gridColumns = 12;
$gridPadding = 15px;


//- BREAKPOINTS
note = 'all and (max-width: 1759px)';
laptop = 'all and (max-width: 1399px)';
tablet = 'all and (max-width: 1279px)';
pad = 'all and (max-width: 1023px)';
phone = 'all and (max-width: 767px)';

$points = note laptop tablet pad phone;
$pointsPrefix = '-nt' '-lp' '-tb' '-pd' '-ph';


/*!
	BREAKPOINTS

	iPad Pro - 1366, 1024
	macbook old = 1280
	iPad - 1024, 768
	iPhone - 414, 375, 320

	note = 'all and (max-width: 1759px)', -nt
	laptop = 'all and (max-width: 1399px)', -lp
	tablet = 'all and (max-width: 1279px)', -tb
	pad = 'all and (max-width: 1023px)', -pd
	phone = 'all and (max-width: 767px)', -ph
*/


/*!
	FONTS
	===============================================
*/
/*!
	--- HelveticaNeueCyr ----------------
*/
@font-face
	font-family: 'HelveticaNeueCyr';
	src: local('HelveticaNeueCyr'),
		url('../fonts/hinted-HelveticaNeueCyr-Roman.woff2') format('woff2'),
		url('../fonts/hinted-HelveticaNeueCyr-Roman.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'HelveticaNeueCyr';
	src: local('HelveticaNeueCyr'),
		url('../fonts/hinted-HelveticaNeueCyr-Thin.woff2') format('woff2'),
		url('../fonts/hinted-HelveticaNeueCyr-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'HelveticaNeueCyr';
	src: local('HelveticaNeueCyr-Light'),
		url('../fonts/hinted-HelveticaNeueCyr-Light.woff2') format('woff2'),
		url('../fonts/hinted-HelveticaNeueCyr-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'HelveticaNeueCyr';
	src: local('HelveticaNeueCyr'),
		url('../fonts/hinted-HelveticaNeueCyr-Medium.woff2') format('woff2'),
		url('../fonts/hinted-HelveticaNeueCyr-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'HelveticaNeueCyr';
	src: local('HelveticaNeueCyr'),
		url('../fonts/hinted-HelveticaNeueCyr-Bold.woff2') format('woff2'),
		url('../fonts/hinted-HelveticaNeueCyr-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;


/*!
	--- Montserrat ----------------------
*/
@font-face
	font-family: 'Montserrat';
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('../fonts/hinted-Montserrat-Regular.woff2') format('woff2'),
		url('../fonts/hinted-Montserrat-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'Montserrat';
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url('../fonts/hinted-Montserrat-Light.woff2') format('woff2'),
		url('../fonts/hinted-Montserrat-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'Montserrat';
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('../fonts/hinted-Montserrat-SemiBold.woff2') format('woff2'),
		url('../fonts/hinted-Montserrat-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'Montserrat';
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('../fonts/hinted-Montserrat-Bold.woff2') format('woff2'),
		url('../fonts/hinted-Montserrat-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;


/*!
	--- SF Pro Display ------------------
*/
@font-face
	font-family: 'SF Pro Display';
	src: local('SF Pro Display'), local('SFProDisplay-Regular'),
		url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
		url('../fonts/SFProDisplay-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;

@font-face
	font-family: 'SF Pro Display';
	src: local('SF Pro Display'), local('SFProDisplay-Light'),
		url('../fonts/SFProDisplay-Light.woff2') format('woff2'),
		url('../fonts/SFProDisplay-Light.woff') format('woff');
	font-weight: 200;
	font-style: normal;


/*!
	--- Roboto --------------------------
*/
@font-face
	font-family: 'Roboto';
	src: local('Roboto'), local('Roboto-Regular'),
		url('../fonts/hinted-Roboto-Regular.woff2') format('woff2'),
		url('../fonts/hinted-Roboto-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'Roboto';
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url('../fonts/hinted-Roboto-Bold.woff2') format('woff2'),
		url('../fonts/hinted-Roboto-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;


/*!
	--- Kazimir -----------------------
*/
@font-face
	font-family: 'Kazimir';
	src: local('Kazimir-Regular'),
		url('../fonts/hinted-Kazimir-Regular.woff2') format('woff2'),
		url('../fonts/hinted-Kazimir-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'Kazimir';
	src: local('Kazimir-Light'),
		url('../fonts/hinted-Kazimir-Light.woff2') format('woff2'),
		url('../fonts/hinted-Kazimir-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'Kazimir';
	src: local('Kazimir-Medium'),
		url('../fonts/hinted-Kazimir-Medium.woff2') format('woff2'),
		url('../fonts/hinted-Kazimir-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;

@font-face
	font-family: 'DM Sans';
	src: url('DMSans-Medium.eot');
	src: local('DM Sans Medium'), local('DMSans-Medium'),
		url('../fonts/DMSans-Medium.woff2') format('woff2'),
		url('../fonts/DMSans-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;

@font-face
	font-family: 'DM Sans';
	src: url('DMSans-Bold.eot');
	src: local('DM Sans Bold'), local('DMSans-Bold'),
		url('../fonts/DMSans-Bold.woff2') format('woff2'),
		url('../fonts/DMSans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
