/*
	BREAKPOINTS

	iPad Pro - 1366, 1024
	macbook old = 1280
	iPad - 1024, 768
	iPhone - 414, 375, 320

	note = 'all and (max-width: 1759px)', -nt
	laptop = 'all and (max-width: 1399px)', -lp
	tablet = 'all and (max-width: 1279px)', -tb
	pad = 'all and (max-width: 1023px)', -pd
	phone = 'all and (max-width: 767px)', -ph
*/
/*
	FONTS
	===============================================
*/
/*
	--- HelveticaNeueCyr ----------------
*/
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr'), url("../fonts/hinted-HelveticaNeueCyr-Roman.woff2") format('woff2'), url("../fonts/hinted-HelveticaNeueCyr-Roman.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr'), url("../fonts/hinted-HelveticaNeueCyr-Thin.woff2") format('woff2'), url("../fonts/hinted-HelveticaNeueCyr-Thin.woff") format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr-Light'), url("../fonts/hinted-HelveticaNeueCyr-Light.woff2") format('woff2'), url("../fonts/hinted-HelveticaNeueCyr-Light.woff") format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr'), url("../fonts/hinted-HelveticaNeueCyr-Medium.woff2") format('woff2'), url("../fonts/hinted-HelveticaNeueCyr-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr'), url("../fonts/hinted-HelveticaNeueCyr-Bold.woff2") format('woff2'), url("../fonts/hinted-HelveticaNeueCyr-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/*
	--- Montserrat ----------------------
*/
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url("../fonts/hinted-Montserrat-Regular.woff2") format('woff2'), url("../fonts/hinted-Montserrat-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light'), local('Montserrat-Light'), url("../fonts/hinted-Montserrat-Light.woff2") format('woff2'), url("../fonts/hinted-Montserrat-Light.woff") format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url("../fonts/hinted-Montserrat-SemiBold.woff2") format('woff2'), url("../fonts/hinted-Montserrat-SemiBold.woff") format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url("../fonts/hinted-Montserrat-Bold.woff2") format('woff2'), url("../fonts/hinted-Montserrat-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/*
	--- SF Pro Display ------------------
*/
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), local('SFProDisplay-Regular'), url("../fonts/SFProDisplay-Regular.woff2") format('woff2'), url("../fonts/SFProDisplay-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), local('SFProDisplay-Light'), url("../fonts/SFProDisplay-Light.woff2") format('woff2'), url("../fonts/SFProDisplay-Light.woff") format('woff');
  font-weight: 200;
  font-style: normal;
}
/*
	--- Roboto --------------------------
*/
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'), url("../fonts/hinted-Roboto-Regular.woff2") format('woff2'), url("../fonts/hinted-Roboto-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'), url("../fonts/hinted-Roboto-Bold.woff2") format('woff2'), url("../fonts/hinted-Roboto-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/*
	--- Kazimir -----------------------
*/
@font-face {
  font-family: 'Kazimir';
  src: local('Kazimir-Regular'), url("../fonts/hinted-Kazimir-Regular.woff2") format('woff2'), url("../fonts/hinted-Kazimir-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Kazimir';
  src: local('Kazimir-Light'), url("../fonts/hinted-Kazimir-Light.woff2") format('woff2'), url("../fonts/hinted-Kazimir-Light.woff") format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Kazimir';
  src: local('Kazimir-Medium'), url("../fonts/hinted-Kazimir-Medium.woff2") format('woff2'), url("../fonts/hinted-Kazimir-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'DM Sans';
  src: url("DMSans-Medium.eot");
  src: local('DM Sans Medium'), local('DMSans-Medium'), url("../fonts/DMSans-Medium.woff2") format('woff2'), url("../fonts/DMSans-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'DM Sans';
  src: url("DMSans-Bold.eot");
  src: local('DM Sans Bold'), local('DMSans-Bold'), url("../fonts/DMSans-Bold.woff2") format('woff2'), url("../fonts/DMSans-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
/*
	BASE
	===============================================
	*/
body,
html {
  border: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
html {
  font-size: 16px;
}
html.is-no-scroll,
html.is-unimodal-active {
  overflow: hidden;
}
body {
  font-weight: normal;
  background-color: #fff;
  line-height: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  scroll-behavior: auto;
}
.browsehappy {
  display: block;
  margin: 0;
  padding: 30px;
  text-align: center;
  background: #e55454;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
}
.browsehappy a,
.browsehappy a:hover {
  color: #fff;
  text-decoration: underline;
}
/*
	FOOTER TO BOTTOM
	===============================================
	*/
.wrapper {
  flex: 1 1 auto;
  width: 100%;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
}
.main {
  flex: 1 1 auto;
  min-height: 1vh;
}
.header,
.footer {
  flex-shrink: 0;
}
/*
	UI ELEMENTS
	===============================================
	*/
a {
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: all 0.32s linear;
}
strong,
b {
  font-weight: bold;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  width: auto;
  outline: none;
  line-height: 1;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: rgba(255,255,255,0);
  transition: all 0.32s linear;
}
button,
button[type="submit"],
input[type="submit"] {
  cursor: pointer;
}
input[type="search"],
input[type="number"] {
  -webkit-appearance: textfield;
}
input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button:disabled,
input:disabled,
textarea:disabled,
button.is-disabled,
input.is-disabled,
textarea.is-disabled {
  cursor: not-allowed;
}
textarea {
  width: 100%;
  resize: none;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
select.select,
.select select {
  appearance: none;
}
select.select::-ms-expand,
.select select::-ms-expand {
  display: none;
}
picture {
  display: block;
}
picture img {
  width: 100%;
  display: block;
}
/*
 --- UI -----------------------------------------
*/
body {
  font-family: 'HelveticaNeueCyr', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #273443;
  background: #f3f8fe;
}
.container {
  width: 100%;
  max-width: 1420px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
.link {
  transition: all 0.32s linear;
}
.link:hover {
  opacity: 0.6;
}
.is-process button[type="submit"] {
  position: relative;
}
.is-process button[type="submit"]:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.75);
  border-radius: 10px;
  z-index: 10;
}
.is-process button[type="submit"]:after {
  content: '';
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  border-width: 5px;
  border-color: #111827 #e5e7eb;
  border-style: solid;
  animation: process-rotate 1s ease-in-out infinite forwards;
  z-index: 15;
}
/*
 --- GRID ---------------------
*/
.ui-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -15px;
  margin-right: -15px;
}
.ui-col {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.ui-col-1 {
  max-width: 8.333333%;
}
.ui-col-2 {
  max-width: 16.666666%;
}
.ui-col-3 {
  max-width: 25%;
}
.ui-col-4 {
  max-width: 33.333333%;
}
.ui-col-5 {
  max-width: 41.666666%;
}
.ui-col-6 {
  max-width: 50%;
}
.ui-col-7 {
  max-width: 58.333333%;
}
.ui-col-8 {
  max-width: 66.666666%;
}
.ui-col-9 {
  max-width: 75%;
}
.ui-col-10 {
  max-width: 83.333333%;
}
.ui-col-11 {
  max-width: 91.666666%;
}
.ui-col-12 {
  max-width: 100%;
}
@media all and (max-width: 1759px) {
  .ui-col-1-nt {
    max-width: 8.333333%;
  }
  .ui-col-2-nt {
    max-width: 16.666666%;
  }
  .ui-col-3-nt {
    max-width: 25%;
  }
  .ui-col-4-nt {
    max-width: 33.333333%;
  }
  .ui-col-5-nt {
    max-width: 41.666666%;
  }
  .ui-col-6-nt {
    max-width: 50%;
  }
  .ui-col-7-nt {
    max-width: 58.333333%;
  }
  .ui-col-8-nt {
    max-width: 66.666666%;
  }
  .ui-col-9-nt {
    max-width: 75%;
  }
  .ui-col-10-nt {
    max-width: 83.333333%;
  }
  .ui-col-11-nt {
    max-width: 91.666666%;
  }
  .ui-col-12-nt {
    max-width: 100%;
  }
}
@media all and (max-width: 1399px) {
  .ui-col-1-lp {
    max-width: 8.333333%;
  }
  .ui-col-2-lp {
    max-width: 16.666666%;
  }
  .ui-col-3-lp {
    max-width: 25%;
  }
  .ui-col-4-lp {
    max-width: 33.333333%;
  }
  .ui-col-5-lp {
    max-width: 41.666666%;
  }
  .ui-col-6-lp {
    max-width: 50%;
  }
  .ui-col-7-lp {
    max-width: 58.333333%;
  }
  .ui-col-8-lp {
    max-width: 66.666666%;
  }
  .ui-col-9-lp {
    max-width: 75%;
  }
  .ui-col-10-lp {
    max-width: 83.333333%;
  }
  .ui-col-11-lp {
    max-width: 91.666666%;
  }
  .ui-col-12-lp {
    max-width: 100%;
  }
}
@media all and (max-width: 1279px) {
  .ui-col-1-tb {
    max-width: 8.333333%;
  }
  .ui-col-2-tb {
    max-width: 16.666666%;
  }
  .ui-col-3-tb {
    max-width: 25%;
  }
  .ui-col-4-tb {
    max-width: 33.333333%;
  }
  .ui-col-5-tb {
    max-width: 41.666666%;
  }
  .ui-col-6-tb {
    max-width: 50%;
  }
  .ui-col-7-tb {
    max-width: 58.333333%;
  }
  .ui-col-8-tb {
    max-width: 66.666666%;
  }
  .ui-col-9-tb {
    max-width: 75%;
  }
  .ui-col-10-tb {
    max-width: 83.333333%;
  }
  .ui-col-11-tb {
    max-width: 91.666666%;
  }
  .ui-col-12-tb {
    max-width: 100%;
  }
}
@media all and (max-width: 1023px) {
  .ui-col-1-pd {
    max-width: 8.333333%;
  }
  .ui-col-2-pd {
    max-width: 16.666666%;
  }
  .ui-col-3-pd {
    max-width: 25%;
  }
  .ui-col-4-pd {
    max-width: 33.333333%;
  }
  .ui-col-5-pd {
    max-width: 41.666666%;
  }
  .ui-col-6-pd {
    max-width: 50%;
  }
  .ui-col-7-pd {
    max-width: 58.333333%;
  }
  .ui-col-8-pd {
    max-width: 66.666666%;
  }
  .ui-col-9-pd {
    max-width: 75%;
  }
  .ui-col-10-pd {
    max-width: 83.333333%;
  }
  .ui-col-11-pd {
    max-width: 91.666666%;
  }
  .ui-col-12-pd {
    max-width: 100%;
  }
}
@media all and (max-width: 767px) {
  .ui-col-1-ph {
    max-width: 8.333333%;
  }
  .ui-col-2-ph {
    max-width: 16.666666%;
  }
  .ui-col-3-ph {
    max-width: 25%;
  }
  .ui-col-4-ph {
    max-width: 33.333333%;
  }
  .ui-col-5-ph {
    max-width: 41.666666%;
  }
  .ui-col-6-ph {
    max-width: 50%;
  }
  .ui-col-7-ph {
    max-width: 58.333333%;
  }
  .ui-col-8-ph {
    max-width: 66.666666%;
  }
  .ui-col-9-ph {
    max-width: 75%;
  }
  .ui-col-10-ph {
    max-width: 83.333333%;
  }
  .ui-col-11-ph {
    max-width: 91.666666%;
  }
  .ui-col-12-ph {
    max-width: 100%;
  }
}
.ui-d-flex {
  display: flex;
  flex-wrap: wrap;
}
.ui-d-inline-flex {
  display: inline-flex;
  flex-wrap: wrap;
}
.ui-flex-nowrap {
  flex-wrap: nowrap;
}
.ui-flexgrow-1 {
  flex-grow: 1;
}
.ui-d-block {
  display: block;
}
.ui-d-inline-block {
  display: inline-block;
}
.ui-d-inline {
  display: inline;
}
.ui-d-none {
  display: none;
}
.ui-d-visible {
  visibility: visible;
}
.ui-d-hidden {
  visibility: hidden;
}
.ui-ta-left {
  text-align: left;
}
.ui-ta-center {
  text-align: center;
}
.ui-ta-right {
  text-align: right;
}
.ui-ai-flex-start {
  align-items: flex-start;
}
.ui-jc-flex-start {
  justify-content: flex-start;
}
.ui-as-flex-start {
  align-self: flex-start;
}
.ui-ai-flex-end {
  align-items: flex-end;
}
.ui-jc-flex-end {
  justify-content: flex-end;
}
.ui-as-flex-end {
  align-self: flex-end;
}
.ui-ai-center {
  align-items: center;
}
.ui-jc-center {
  justify-content: center;
}
.ui-as-center {
  align-self: center;
}
.ui-jc-space-between {
  justify-content: space-between;
}
.ui-jc-space-around {
  justify-content: space-around;
}
.ui-flexdir-row {
  flex-direction: row;
}
.ui-flexdir-row-reverse {
  flex-direction: row-reverse;
}
.ui-flexdir-column {
  flex-direction: column;
}
.ui-flexdir-column-reverse {
  flex-direction: column-reverse;
}
.ui-pt-0 {
  padding-top: 0px;
}
.ui-pb-0 {
  padding-bottom: 0px;
}
.ui-mt-0 {
  margin-top: 0px;
}
.ui-mb-0 {
  margin-bottom: 0px;
}
.ui-pt-8 {
  padding-top: 8px;
}
.ui-pb-8 {
  padding-bottom: 8px;
}
.ui-mt-8 {
  margin-top: 8px;
}
.ui-mb-8 {
  margin-bottom: 8px;
}
.ui-pt-16 {
  padding-top: 16px;
}
.ui-pb-16 {
  padding-bottom: 16px;
}
.ui-mt-16 {
  margin-top: 16px;
}
.ui-mb-16 {
  margin-bottom: 16px;
}
.ui-pt-24 {
  padding-top: 24px;
}
.ui-pb-24 {
  padding-bottom: 24px;
}
.ui-mt-24 {
  margin-top: 24px;
}
.ui-mb-24 {
  margin-bottom: 24px;
}
.ui-pt-32 {
  padding-top: 32px;
}
.ui-pb-32 {
  padding-bottom: 32px;
}
.ui-mt-32 {
  margin-top: 32px;
}
.ui-mb-32 {
  margin-bottom: 32px;
}
.ui-pt-64 {
  padding-top: 64px;
}
.ui-pb-64 {
  padding-bottom: 64px;
}
.ui-mt-64 {
  margin-top: 64px;
}
.ui-mb-64 {
  margin-bottom: 64px;
}
.ui-pt-128 {
  padding-top: 128px;
}
.ui-pb-128 {
  padding-bottom: 128px;
}
.ui-mt-128 {
  margin-top: 128px;
}
.ui-mb-128 {
  margin-bottom: 128px;
}
.ui-mx-auto-left {
  margin-left: auto;
  margin-right: initial;
}
.ui-mx-auto-right {
  margin-left: initial;
  margin-right: left;
}
.ui-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.ui-mx-none {
  margin-left: initial;
  margin-right: initial;
}
.ui-mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.ui-w-100 {
  width: 100%;
}
.ui-w-auto {
  width: auto;
  max-width: none;
}
.ui-px-0 {
  padding-left: 0;
  padding-right: 0;
}
@media all and (max-width: 1759px) {
  .ui-d-flex-nt {
    display: flex;
    flex-wrap: wrap;
  }
  .ui-d-inline-flex-nt {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .ui-flex-nowrap-nt {
    flex-wrap: nowrap;
  }
  .ui-flexgrow-1-nt {
    flex-grow: 1;
  }
  .ui-d-block-nt {
    display: block;
  }
  .ui-d-inline-block-nt {
    display: inline-block;
  }
  .ui-d-inline-nt {
    display: inline;
  }
  .ui-d-none-nt {
    display: none;
  }
  .ui-d-visible-nt {
    visibility: visible;
  }
  .ui-d-hidden-nt {
    visibility: hidden;
  }
  .ui-ta-left-nt {
    text-align: left;
  }
  .ui-ta-center-nt {
    text-align: center;
  }
  .ui-ta-right-nt {
    text-align: right;
  }
  .ui-ai-flex-start-nt {
    align-items: flex-start;
  }
  .ui-jc-flex-start-nt {
    justify-content: flex-start;
  }
  .ui-as-flex-start-nt {
    align-self: flex-start;
  }
  .ui-ai-flex-end-nt {
    align-items: flex-end;
  }
  .ui-jc-flex-end-nt {
    justify-content: flex-end;
  }
  .ui-as-flex-end-nt {
    align-self: flex-end;
  }
  .ui-ai-center-nt {
    align-items: center;
  }
  .ui-jc-center-nt {
    justify-content: center;
  }
  .ui-as-center-nt {
    align-self: center;
  }
  .ui-jc-space-between-nt {
    justify-content: space-between;
  }
  .ui-jc-space-around-nt {
    justify-content: space-around;
  }
  .ui-flexdir-row-nt {
    flex-direction: row;
  }
  .ui-flexdir-row-reverse-nt {
    flex-direction: row-reverse;
  }
  .ui-flexdir-column-nt {
    flex-direction: column;
  }
  .ui-flexdir-column-reverse-nt {
    flex-direction: column-reverse;
  }
  .ui-pt-0-nt {
    padding-top: 0px;
  }
  .ui-pb-0-nt {
    padding-bottom: 0px;
  }
  .ui-mt-0-nt {
    margin-top: 0px;
  }
  .ui-mb-0-nt {
    margin-bottom: 0px;
  }
  .ui-pt-8-nt {
    padding-top: 8px;
  }
  .ui-pb-8-nt {
    padding-bottom: 8px;
  }
  .ui-mt-8-nt {
    margin-top: 8px;
  }
  .ui-mb-8-nt {
    margin-bottom: 8px;
  }
  .ui-pt-16-nt {
    padding-top: 16px;
  }
  .ui-pb-16-nt {
    padding-bottom: 16px;
  }
  .ui-mt-16-nt {
    margin-top: 16px;
  }
  .ui-mb-16-nt {
    margin-bottom: 16px;
  }
  .ui-pt-24-nt {
    padding-top: 24px;
  }
  .ui-pb-24-nt {
    padding-bottom: 24px;
  }
  .ui-mt-24-nt {
    margin-top: 24px;
  }
  .ui-mb-24-nt {
    margin-bottom: 24px;
  }
  .ui-pt-32-nt {
    padding-top: 32px;
  }
  .ui-pb-32-nt {
    padding-bottom: 32px;
  }
  .ui-mt-32-nt {
    margin-top: 32px;
  }
  .ui-mb-32-nt {
    margin-bottom: 32px;
  }
  .ui-pt-64-nt {
    padding-top: 64px;
  }
  .ui-pb-64-nt {
    padding-bottom: 64px;
  }
  .ui-mt-64-nt {
    margin-top: 64px;
  }
  .ui-mb-64-nt {
    margin-bottom: 64px;
  }
  .ui-pt-128-nt {
    padding-top: 128px;
  }
  .ui-pb-128-nt {
    padding-bottom: 128px;
  }
  .ui-mt-128-nt {
    margin-top: 128px;
  }
  .ui-mb-128-nt {
    margin-bottom: 128px;
  }
  .ui-mx-auto-left-nt {
    margin-left: auto;
    margin-right: initial;
  }
  .ui-mx-auto-right-nt {
    margin-left: initial;
    margin-right: left;
  }
  .ui-mx-auto-nt {
    margin-left: auto;
    margin-right: auto;
  }
  .ui-mx-none-nt {
    margin-left: initial;
    margin-right: initial;
  }
  .ui-mx-0-nt {
    margin-left: 0;
    margin-right: 0;
  }
  .ui-w-100-nt {
    width: 100%;
  }
  .ui-w-auto-nt {
    width: auto;
    max-width: none;
  }
  .ui-px-0-nt {
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (max-width: 1399px) {
  .ui-d-flex-lp {
    display: flex;
    flex-wrap: wrap;
  }
  .ui-d-inline-flex-lp {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .ui-flex-nowrap-lp {
    flex-wrap: nowrap;
  }
  .ui-flexgrow-1-lp {
    flex-grow: 1;
  }
  .ui-d-block-lp {
    display: block;
  }
  .ui-d-inline-block-lp {
    display: inline-block;
  }
  .ui-d-inline-lp {
    display: inline;
  }
  .ui-d-none-lp {
    display: none;
  }
  .ui-d-visible-lp {
    visibility: visible;
  }
  .ui-d-hidden-lp {
    visibility: hidden;
  }
  .ui-ta-left-lp {
    text-align: left;
  }
  .ui-ta-center-lp {
    text-align: center;
  }
  .ui-ta-right-lp {
    text-align: right;
  }
  .ui-ai-flex-start-lp {
    align-items: flex-start;
  }
  .ui-jc-flex-start-lp {
    justify-content: flex-start;
  }
  .ui-as-flex-start-lp {
    align-self: flex-start;
  }
  .ui-ai-flex-end-lp {
    align-items: flex-end;
  }
  .ui-jc-flex-end-lp {
    justify-content: flex-end;
  }
  .ui-as-flex-end-lp {
    align-self: flex-end;
  }
  .ui-ai-center-lp {
    align-items: center;
  }
  .ui-jc-center-lp {
    justify-content: center;
  }
  .ui-as-center-lp {
    align-self: center;
  }
  .ui-jc-space-between-lp {
    justify-content: space-between;
  }
  .ui-jc-space-around-lp {
    justify-content: space-around;
  }
  .ui-flexdir-row-lp {
    flex-direction: row;
  }
  .ui-flexdir-row-reverse-lp {
    flex-direction: row-reverse;
  }
  .ui-flexdir-column-lp {
    flex-direction: column;
  }
  .ui-flexdir-column-reverse-lp {
    flex-direction: column-reverse;
  }
  .ui-pt-0-lp {
    padding-top: 0px;
  }
  .ui-pb-0-lp {
    padding-bottom: 0px;
  }
  .ui-mt-0-lp {
    margin-top: 0px;
  }
  .ui-mb-0-lp {
    margin-bottom: 0px;
  }
  .ui-pt-8-lp {
    padding-top: 8px;
  }
  .ui-pb-8-lp {
    padding-bottom: 8px;
  }
  .ui-mt-8-lp {
    margin-top: 8px;
  }
  .ui-mb-8-lp {
    margin-bottom: 8px;
  }
  .ui-pt-16-lp {
    padding-top: 16px;
  }
  .ui-pb-16-lp {
    padding-bottom: 16px;
  }
  .ui-mt-16-lp {
    margin-top: 16px;
  }
  .ui-mb-16-lp {
    margin-bottom: 16px;
  }
  .ui-pt-24-lp {
    padding-top: 24px;
  }
  .ui-pb-24-lp {
    padding-bottom: 24px;
  }
  .ui-mt-24-lp {
    margin-top: 24px;
  }
  .ui-mb-24-lp {
    margin-bottom: 24px;
  }
  .ui-pt-32-lp {
    padding-top: 32px;
  }
  .ui-pb-32-lp {
    padding-bottom: 32px;
  }
  .ui-mt-32-lp {
    margin-top: 32px;
  }
  .ui-mb-32-lp {
    margin-bottom: 32px;
  }
  .ui-pt-64-lp {
    padding-top: 64px;
  }
  .ui-pb-64-lp {
    padding-bottom: 64px;
  }
  .ui-mt-64-lp {
    margin-top: 64px;
  }
  .ui-mb-64-lp {
    margin-bottom: 64px;
  }
  .ui-pt-128-lp {
    padding-top: 128px;
  }
  .ui-pb-128-lp {
    padding-bottom: 128px;
  }
  .ui-mt-128-lp {
    margin-top: 128px;
  }
  .ui-mb-128-lp {
    margin-bottom: 128px;
  }
  .ui-mx-auto-left-lp {
    margin-left: auto;
    margin-right: initial;
  }
  .ui-mx-auto-right-lp {
    margin-left: initial;
    margin-right: left;
  }
  .ui-mx-auto-lp {
    margin-left: auto;
    margin-right: auto;
  }
  .ui-mx-none-lp {
    margin-left: initial;
    margin-right: initial;
  }
  .ui-mx-0-lp {
    margin-left: 0;
    margin-right: 0;
  }
  .ui-w-100-lp {
    width: 100%;
  }
  .ui-w-auto-lp {
    width: auto;
    max-width: none;
  }
  .ui-px-0-lp {
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (max-width: 1279px) {
  .ui-d-flex-tb {
    display: flex;
    flex-wrap: wrap;
  }
  .ui-d-inline-flex-tb {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .ui-flex-nowrap-tb {
    flex-wrap: nowrap;
  }
  .ui-flexgrow-1-tb {
    flex-grow: 1;
  }
  .ui-d-block-tb {
    display: block;
  }
  .ui-d-inline-block-tb {
    display: inline-block;
  }
  .ui-d-inline-tb {
    display: inline;
  }
  .ui-d-none-tb {
    display: none;
  }
  .ui-d-visible-tb {
    visibility: visible;
  }
  .ui-d-hidden-tb {
    visibility: hidden;
  }
  .ui-ta-left-tb {
    text-align: left;
  }
  .ui-ta-center-tb {
    text-align: center;
  }
  .ui-ta-right-tb {
    text-align: right;
  }
  .ui-ai-flex-start-tb {
    align-items: flex-start;
  }
  .ui-jc-flex-start-tb {
    justify-content: flex-start;
  }
  .ui-as-flex-start-tb {
    align-self: flex-start;
  }
  .ui-ai-flex-end-tb {
    align-items: flex-end;
  }
  .ui-jc-flex-end-tb {
    justify-content: flex-end;
  }
  .ui-as-flex-end-tb {
    align-self: flex-end;
  }
  .ui-ai-center-tb {
    align-items: center;
  }
  .ui-jc-center-tb {
    justify-content: center;
  }
  .ui-as-center-tb {
    align-self: center;
  }
  .ui-jc-space-between-tb {
    justify-content: space-between;
  }
  .ui-jc-space-around-tb {
    justify-content: space-around;
  }
  .ui-flexdir-row-tb {
    flex-direction: row;
  }
  .ui-flexdir-row-reverse-tb {
    flex-direction: row-reverse;
  }
  .ui-flexdir-column-tb {
    flex-direction: column;
  }
  .ui-flexdir-column-reverse-tb {
    flex-direction: column-reverse;
  }
  .ui-pt-0-tb {
    padding-top: 0px;
  }
  .ui-pb-0-tb {
    padding-bottom: 0px;
  }
  .ui-mt-0-tb {
    margin-top: 0px;
  }
  .ui-mb-0-tb {
    margin-bottom: 0px;
  }
  .ui-pt-8-tb {
    padding-top: 8px;
  }
  .ui-pb-8-tb {
    padding-bottom: 8px;
  }
  .ui-mt-8-tb {
    margin-top: 8px;
  }
  .ui-mb-8-tb {
    margin-bottom: 8px;
  }
  .ui-pt-16-tb {
    padding-top: 16px;
  }
  .ui-pb-16-tb {
    padding-bottom: 16px;
  }
  .ui-mt-16-tb {
    margin-top: 16px;
  }
  .ui-mb-16-tb {
    margin-bottom: 16px;
  }
  .ui-pt-24-tb {
    padding-top: 24px;
  }
  .ui-pb-24-tb {
    padding-bottom: 24px;
  }
  .ui-mt-24-tb {
    margin-top: 24px;
  }
  .ui-mb-24-tb {
    margin-bottom: 24px;
  }
  .ui-pt-32-tb {
    padding-top: 32px;
  }
  .ui-pb-32-tb {
    padding-bottom: 32px;
  }
  .ui-mt-32-tb {
    margin-top: 32px;
  }
  .ui-mb-32-tb {
    margin-bottom: 32px;
  }
  .ui-pt-64-tb {
    padding-top: 64px;
  }
  .ui-pb-64-tb {
    padding-bottom: 64px;
  }
  .ui-mt-64-tb {
    margin-top: 64px;
  }
  .ui-mb-64-tb {
    margin-bottom: 64px;
  }
  .ui-pt-128-tb {
    padding-top: 128px;
  }
  .ui-pb-128-tb {
    padding-bottom: 128px;
  }
  .ui-mt-128-tb {
    margin-top: 128px;
  }
  .ui-mb-128-tb {
    margin-bottom: 128px;
  }
  .ui-mx-auto-left-tb {
    margin-left: auto;
    margin-right: initial;
  }
  .ui-mx-auto-right-tb {
    margin-left: initial;
    margin-right: left;
  }
  .ui-mx-auto-tb {
    margin-left: auto;
    margin-right: auto;
  }
  .ui-mx-none-tb {
    margin-left: initial;
    margin-right: initial;
  }
  .ui-mx-0-tb {
    margin-left: 0;
    margin-right: 0;
  }
  .ui-w-100-tb {
    width: 100%;
  }
  .ui-w-auto-tb {
    width: auto;
    max-width: none;
  }
  .ui-px-0-tb {
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (max-width: 1023px) {
  .ui-d-flex-pd {
    display: flex;
    flex-wrap: wrap;
  }
  .ui-d-inline-flex-pd {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .ui-flex-nowrap-pd {
    flex-wrap: nowrap;
  }
  .ui-flexgrow-1-pd {
    flex-grow: 1;
  }
  .ui-d-block-pd {
    display: block;
  }
  .ui-d-inline-block-pd {
    display: inline-block;
  }
  .ui-d-inline-pd {
    display: inline;
  }
  .ui-d-none-pd {
    display: none;
  }
  .ui-d-visible-pd {
    visibility: visible;
  }
  .ui-d-hidden-pd {
    visibility: hidden;
  }
  .ui-ta-left-pd {
    text-align: left;
  }
  .ui-ta-center-pd {
    text-align: center;
  }
  .ui-ta-right-pd {
    text-align: right;
  }
  .ui-ai-flex-start-pd {
    align-items: flex-start;
  }
  .ui-jc-flex-start-pd {
    justify-content: flex-start;
  }
  .ui-as-flex-start-pd {
    align-self: flex-start;
  }
  .ui-ai-flex-end-pd {
    align-items: flex-end;
  }
  .ui-jc-flex-end-pd {
    justify-content: flex-end;
  }
  .ui-as-flex-end-pd {
    align-self: flex-end;
  }
  .ui-ai-center-pd {
    align-items: center;
  }
  .ui-jc-center-pd {
    justify-content: center;
  }
  .ui-as-center-pd {
    align-self: center;
  }
  .ui-jc-space-between-pd {
    justify-content: space-between;
  }
  .ui-jc-space-around-pd {
    justify-content: space-around;
  }
  .ui-flexdir-row-pd {
    flex-direction: row;
  }
  .ui-flexdir-row-reverse-pd {
    flex-direction: row-reverse;
  }
  .ui-flexdir-column-pd {
    flex-direction: column;
  }
  .ui-flexdir-column-reverse-pd {
    flex-direction: column-reverse;
  }
  .ui-pt-0-pd {
    padding-top: 0px;
  }
  .ui-pb-0-pd {
    padding-bottom: 0px;
  }
  .ui-mt-0-pd {
    margin-top: 0px;
  }
  .ui-mb-0-pd {
    margin-bottom: 0px;
  }
  .ui-pt-8-pd {
    padding-top: 8px;
  }
  .ui-pb-8-pd {
    padding-bottom: 8px;
  }
  .ui-mt-8-pd {
    margin-top: 8px;
  }
  .ui-mb-8-pd {
    margin-bottom: 8px;
  }
  .ui-pt-16-pd {
    padding-top: 16px;
  }
  .ui-pb-16-pd {
    padding-bottom: 16px;
  }
  .ui-mt-16-pd {
    margin-top: 16px;
  }
  .ui-mb-16-pd {
    margin-bottom: 16px;
  }
  .ui-pt-24-pd {
    padding-top: 24px;
  }
  .ui-pb-24-pd {
    padding-bottom: 24px;
  }
  .ui-mt-24-pd {
    margin-top: 24px;
  }
  .ui-mb-24-pd {
    margin-bottom: 24px;
  }
  .ui-pt-32-pd {
    padding-top: 32px;
  }
  .ui-pb-32-pd {
    padding-bottom: 32px;
  }
  .ui-mt-32-pd {
    margin-top: 32px;
  }
  .ui-mb-32-pd {
    margin-bottom: 32px;
  }
  .ui-pt-64-pd {
    padding-top: 64px;
  }
  .ui-pb-64-pd {
    padding-bottom: 64px;
  }
  .ui-mt-64-pd {
    margin-top: 64px;
  }
  .ui-mb-64-pd {
    margin-bottom: 64px;
  }
  .ui-pt-128-pd {
    padding-top: 128px;
  }
  .ui-pb-128-pd {
    padding-bottom: 128px;
  }
  .ui-mt-128-pd {
    margin-top: 128px;
  }
  .ui-mb-128-pd {
    margin-bottom: 128px;
  }
  .ui-mx-auto-left-pd {
    margin-left: auto;
    margin-right: initial;
  }
  .ui-mx-auto-right-pd {
    margin-left: initial;
    margin-right: left;
  }
  .ui-mx-auto-pd {
    margin-left: auto;
    margin-right: auto;
  }
  .ui-mx-none-pd {
    margin-left: initial;
    margin-right: initial;
  }
  .ui-mx-0-pd {
    margin-left: 0;
    margin-right: 0;
  }
  .ui-w-100-pd {
    width: 100%;
  }
  .ui-w-auto-pd {
    width: auto;
    max-width: none;
  }
  .ui-px-0-pd {
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (max-width: 767px) {
  .ui-d-flex-ph {
    display: flex;
    flex-wrap: wrap;
  }
  .ui-d-inline-flex-ph {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .ui-flex-nowrap-ph {
    flex-wrap: nowrap;
  }
  .ui-flexgrow-1-ph {
    flex-grow: 1;
  }
  .ui-d-block-ph {
    display: block;
  }
  .ui-d-inline-block-ph {
    display: inline-block;
  }
  .ui-d-inline-ph {
    display: inline;
  }
  .ui-d-none-ph {
    display: none;
  }
  .ui-d-visible-ph {
    visibility: visible;
  }
  .ui-d-hidden-ph {
    visibility: hidden;
  }
  .ui-ta-left-ph {
    text-align: left;
  }
  .ui-ta-center-ph {
    text-align: center;
  }
  .ui-ta-right-ph {
    text-align: right;
  }
  .ui-ai-flex-start-ph {
    align-items: flex-start;
  }
  .ui-jc-flex-start-ph {
    justify-content: flex-start;
  }
  .ui-as-flex-start-ph {
    align-self: flex-start;
  }
  .ui-ai-flex-end-ph {
    align-items: flex-end;
  }
  .ui-jc-flex-end-ph {
    justify-content: flex-end;
  }
  .ui-as-flex-end-ph {
    align-self: flex-end;
  }
  .ui-ai-center-ph {
    align-items: center;
  }
  .ui-jc-center-ph {
    justify-content: center;
  }
  .ui-as-center-ph {
    align-self: center;
  }
  .ui-jc-space-between-ph {
    justify-content: space-between;
  }
  .ui-jc-space-around-ph {
    justify-content: space-around;
  }
  .ui-flexdir-row-ph {
    flex-direction: row;
  }
  .ui-flexdir-row-reverse-ph {
    flex-direction: row-reverse;
  }
  .ui-flexdir-column-ph {
    flex-direction: column;
  }
  .ui-flexdir-column-reverse-ph {
    flex-direction: column-reverse;
  }
  .ui-pt-0-ph {
    padding-top: 0px;
  }
  .ui-pb-0-ph {
    padding-bottom: 0px;
  }
  .ui-mt-0-ph {
    margin-top: 0px;
  }
  .ui-mb-0-ph {
    margin-bottom: 0px;
  }
  .ui-pt-8-ph {
    padding-top: 8px;
  }
  .ui-pb-8-ph {
    padding-bottom: 8px;
  }
  .ui-mt-8-ph {
    margin-top: 8px;
  }
  .ui-mb-8-ph {
    margin-bottom: 8px;
  }
  .ui-pt-16-ph {
    padding-top: 16px;
  }
  .ui-pb-16-ph {
    padding-bottom: 16px;
  }
  .ui-mt-16-ph {
    margin-top: 16px;
  }
  .ui-mb-16-ph {
    margin-bottom: 16px;
  }
  .ui-pt-24-ph {
    padding-top: 24px;
  }
  .ui-pb-24-ph {
    padding-bottom: 24px;
  }
  .ui-mt-24-ph {
    margin-top: 24px;
  }
  .ui-mb-24-ph {
    margin-bottom: 24px;
  }
  .ui-pt-32-ph {
    padding-top: 32px;
  }
  .ui-pb-32-ph {
    padding-bottom: 32px;
  }
  .ui-mt-32-ph {
    margin-top: 32px;
  }
  .ui-mb-32-ph {
    margin-bottom: 32px;
  }
  .ui-pt-64-ph {
    padding-top: 64px;
  }
  .ui-pb-64-ph {
    padding-bottom: 64px;
  }
  .ui-mt-64-ph {
    margin-top: 64px;
  }
  .ui-mb-64-ph {
    margin-bottom: 64px;
  }
  .ui-pt-128-ph {
    padding-top: 128px;
  }
  .ui-pb-128-ph {
    padding-bottom: 128px;
  }
  .ui-mt-128-ph {
    margin-top: 128px;
  }
  .ui-mb-128-ph {
    margin-bottom: 128px;
  }
  .ui-mx-auto-left-ph {
    margin-left: auto;
    margin-right: initial;
  }
  .ui-mx-auto-right-ph {
    margin-left: initial;
    margin-right: left;
  }
  .ui-mx-auto-ph {
    margin-left: auto;
    margin-right: auto;
  }
  .ui-mx-none-ph {
    margin-left: initial;
    margin-right: initial;
  }
  .ui-mx-0-ph {
    margin-left: 0;
    margin-right: 0;
  }
  .ui-w-100-ph {
    width: 100%;
  }
  .ui-w-auto-ph {
    width: auto;
    max-width: none;
  }
  .ui-px-0-ph {
    padding-left: 0;
    padding-right: 0;
  }
}
/*
	--- BASIC ---------------------------
*/
.ui-icon {
  line-height: 1;
  width: 16px;
  height: 16px;
  flex: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ui-icon .ico {
  width: 100%;
  height: 100%;
}
.ui-pic-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ui-pic-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ui-pic-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ui-pic-contain img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ui-tab {
  display: none;
  opacity: 0;
}
.ui-tab.is-active {
  display: block;
  animation: tab-show 1s linear 0.01s 1 forwards;
}
.ui-iframe-box {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.ui-iframe-box iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.ui-ul-clear {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.ui-burger {
  width: 32px;
  height: 24px;
  position: relative;
}
.ui-burger:before,
.ui-burger:after {
  content: '';
  display: block;
  position: absolute;
}
.ui-burger:before,
.ui-burger:after,
.ui-burger span {
  width: 100%;
  height: 4px;
  left: 0;
  background: #f00;
  border-radius: 10px;
  transition: all 0.32s linear;
}
.ui-burger:before {
  top: 0;
}
.ui-burger:after {
  top: 100%;
  margin-top: -4px;
}
.ui-burger span {
  top: 50%;
  margin-top: 0;
}
.ui-burger.is-active span {
  opacity: 0;
}
.ui-burger.is-active:before,
.ui-burger.is-active:after {
  top: 50%;
  margin-top: -2px;
}
.ui-burger.is-active:before {
  transform: rotate(45deg);
}
.ui-burger.is-active:after {
  transform: rotate(-45deg);
}
/*
	--- FORM ----------------------------
*/
.ui-fg {
  position: relative;
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .ui-fg {
    margin-bottom: 15px;
  }
}
.ui-fg.is-mb-0 {
  margin-bottom: 0;
}
.ui-fg .error-message {
  position: absolute;
  bottom: 3px;
  right: 5px;
  font-size: 0.8em;
  color: #ef4444;
}
.ui-btn {
  height: 56px;
  font-size: 17px;
  font-weight: 500;
  background-color: #27a9fb;
  color: #fff;
  border-radius: 100px;
  padding: 0 42px;
}
@media all and (max-width: 1023px) {
  .ui-btn {
    padding: 0 42px;
  }
}
.ui-btn:hover {
  box-shadow: 0px 6px 20px #a7bed9;
}
.ui-btn.is-min-w {
  min-width: 225px;
}
@media all and (max-width: 767px) {
  .ui-btn.is-min-w {
    min-width: auto;
  }
}
.ui-btn.is-thin {
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 0.88;
}
.ui-btn.is-white {
  background-color: #fff;
  color: #27a9fb;
  box-shadow: 0px 4px 10px rgba(210,222,236,0.5);
}
.ui-btn.is-white:hover {
  background-color: #27a9fb;
  color: #fff;
}
.ui-btn.is-gray {
  background-color: #96a8be;
  color: #fff;
}
.ui-btn.is-gray.is-ico {
  fill: #fff;
}
.ui-btn.is-gray:hover {
  box-shadow: 0px 6px 20px #a7bed9;
  background-color: #27a9fb;
  color: #fff;
}
.ui-btn.is-ico {
  padding: 0 20px;
  fill: #27a9fb;
}
.ui-btn.is-ico .ui-icon {
  width: 27px;
  height: 21px;
  margin-right: 15px;
}
.ui-btn.is-form {
  height: 36px;
  border-radius: 10px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
}
.ui-btn.is-file {
  height: 36px;
  border-radius: 10px;
  padding: 0 15px 0 20px;
}
.ui-btn.is-file button {
  flex: none;
  margin-left: 25px;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  fill: #fff;
}
.ui-btn.is-file button .ico {
  width: 100%;
  height: 100%;
}
.ui-btn-round {
  border-radius: 100px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(113,113,113,0.25);
  fill: #27a9fb;
}
@media all and (max-width: 767px) {
  .ui-btn-round {
    width: 35px;
    height: 35px;
  }
}
.ui-btn-round .ico {
  width: 25px;
  height: 25px;
}
@media all and (max-width: 767px) {
  .ui-btn-round .ico {
    width: 18px;
    height: 18px;
  }
}
.download-link {
  display: inline-flex;
  align-items: center;
  background-color: #96a8be;
  color: #fff;
  padding: 12px 20px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  border-radius: 100px;
}
@media all and (max-width: 767px) {
  .download-link {
    width: 100%;
    max-width: 420px;
  }
}
.download-link:before {
  content: '';
  display: inline-block;
  flex: none;
  width: 27px;
  height: 21px;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/cloud-download.svg");
}
.download-link:hover {
  box-shadow: 0px 6px 20px #a7bed9;
  background-color: #27a9fb;
  color: #fff;
}
.ui-label {
  font-weight: 300;
  margin-bottom: 15px;
}
.ui-input::placeholder {
  color: rgba(39,52,67,0.4);
}
.ui-input-form {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #96a8be;
  height: 40px;
  padding: 0 16px;
}
.ui-input-form.is-ta {
  height: auto;
  padding: 16px;
}
.ui-widget {
  background-color: #fff;
  border-radius: 10px;
  padding: 12px 16px;
  transition: all 0.32s linear;
}
@media all and (max-width: 767px) {
  .ui-widget {
    padding: 8px 10px;
    border-radius: 5px;
  }
}
.ui-widget-pic {
  flex: none;
  width: 58px;
  height: 58px;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  .ui-widget-pic {
    width: 36px;
    height: 36px;
  }
}
.ui-widget-pic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ui-widget-pic.is-round {
  border-radius: 100px;
}
.ui-widget-pic.is-round img {
  object-fit: cover;
}
.ui-widget-body {
  padding-left: 26px;
  color: #96a8be;
  line-height: 1.13;
}
@media all and (max-width: 1279px) {
  .ui-widget-body {
    padding-left: 16px;
  }
}
@media all and (max-width: 767px) {
  .ui-widget-body {
    font-size: 10px;
    line-height: 1.1;
  }
}
.ui-widget-body p:not(:last-child) {
  margin-bottom: 5px;
}
@media all and (max-width: 767px) {
  .ui-widget-body p:not(:last-child) {
    margin-bottom: 2px;
  }
}
.ui-widget-body strong {
  font-family: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #27a9fb;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
}
@media all and (max-width: 767px) {
  .ui-widget-body strong {
    font-size: 14px;
  }
}
.ui-widget:hover {
  box-shadow: 0px 4px 10px rgba(210,222,236,0.5);
}
@-moz-keyframes process-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes process-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes process-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes process-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*
	--- BLOCKS ------------------------------------
*/
.js-gsap-canvas {
  opacity: 0;
}
.is-main-menu-open .main:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(153,159,169,0.4);
  z-index: 10;
  transition: all 0.32s linear;
}
.bSlPagination .bullet {
  display: block;
  width: 14px;
  height: 14px;
  border: 1px solid #27a9fb;
  border-radius: 50px;
}
@media all and (max-width: 767px) {
  .bSlPagination .bullet {
    width: 12px;
    height: 12px;
  }
}
.bSlPagination .bullet.is-active {
  background-color: #27a9fb;
}
.bSlPagination .bullet:not(:last-child) {
  margin-right: 14px;
}
@media all and (max-width: 767px) {
  .bSlPagination .bullet:not(:last-child) {
    margin-right: 12px;
  }
}
.bSection:not(:last-child) {
  margin-bottom: 30px;
}
.bSection.is-w-grid:not(:last-child) {
  margin-bottom: 0;
}
.bSection-box {
  max-width: 1204px;
}
.bSectionTitle {
  color: #27a9fb;
  font-size: 25px;
  line-height: 1.2;
  font-weight: 500;
  fill: #27a9fb;
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .bSectionTitle {
    font-size: 20px;
  }
}
.bSectionTitle .ui-icon {
  width: 20px;
  height: 20px;
  margin-left: 15px;
}
.bCardGrid-col {
  padding-bottom: 30px;
}
.bCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px 28px;
  height: 100%;
}
@media all and (max-width: 1023px) {
  .bCard {
    padding: 10px 10px 18px;
  }
}
.bCardLinkArrow {
  flex: none;
  width: 26px;
  height: 26px;
  background-color: #e9f3ff;
  box-shadow: 0px 1px 2px rgba(113,113,113,0.25);
  border-radius: 100px;
  fill: #27a9fb;
}
.bCardLinkArrow .ico {
  width: 14px;
  height: 10px;
}
.bTag {
  color: #27a9fb;
  background-color: #e9f3ff;
  font-weight: 500;
  font-size: 12px;
  border-radius: 50px;
  min-width: 118px;
  padding: 7px 5px;
}
@media all and (max-width: 767px) {
  .bTag {
    min-width: 95px;
    padding: 6px 5px;
    font-size: 10px;
  }
}
.bTag:not(:last-child) {
  margin-right: 15px;
}
@media all and (max-width: 767px) {
  .bTag:not(:last-child) {
    margin-right: 12px;
  }
}
.bSocials-item:not(:last-child) {
  margin-right: 10px;
}
.bSocials-link {
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(113,113,113,0.25);
}
.bSocials-link:hover {
  box-shadow: 0px 4px 10px #d2deec;
}
.bSocials .ui-icon {
  width: 21px;
  height: 21px;
}
.bFormFunctions {
  margin-right: 30px;
}
.bFormFunctions-item {
  width: 22px;
  height: 22px;
  fill: #b3b9c5;
}
.bFormFunctions-item:not(:last-child) {
  margin-right: 15px;
}
.bGraphHorizontal-item:not(:last-child) {
  margin-bottom: 10px;
}
.bGraphVertical {
  height: 100%;
  max-height: 195px;
}
.bGraphLines {
  height: 100%;
}
.bGraphLine {
  font-family: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 13px;
  line-height: 1.38;
  color: #4e5d78;
}
.bGraphLine-top {
  margin-bottom: 4px;
}
.bGraphLine-val {
  font-weight: bold;
}
.bGraphLine-scale {
  height: 9px;
  background-color: #e9f3ff;
  border-radius: 30px;
  position: relative;
}
.bGraphLine-scale span {
  display: block;
  background-color: #00cb00;
  border-radius: 30px;
  height: 100%;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.bDayNumber {
  font-size: 20px;
  line-height: 1.2;
  color: #4e5d78;
}
@media all and (max-width: 1023px) {
  .bDayNumber {
    font-size: 13px;
  }
}
.bDayNumber-val {
  color: #27a9fb;
  font-family: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 70px;
  font-weight: bold;
  margin-right: 20px;
}
@media all and (max-width: 1023px) {
  .bDayNumber-val {
    font-size: 44px;
  }
}
.bPage {
  margin-bottom: 60px;
}
@media all and (max-width: 767px) {
  .bPage {
    margin-top: 22px;
  }
}
.bPageTitle {
  font-size: 25px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .bPageTitle {
    font-size: 20px;
  }
}
.bTagsGroup {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  margin-left: -15px;
  margin-right: -15px;
}
@media all and (max-width: 767px) {
  .bTagsGroup {
    font-size: 18px;
    margin-left: -8px;
    margin-right: -8px;
  }
}
.bTagsGroup-link {
  background-color: #fff;
  color: #27a9fb;
  min-width: 142px;
  height: 30px;
  border-radius: 50px;
  padding: 0 14px;
  white-space: nowrap;
  margin-bottom: 25px;
}
@media all and (max-width: 767px) {
  .bTagsGroup-link {
    min-width: auto;
    width: 100%;
    height: 40px;
  }
}
.bTagsGroup-link.is-active {
  background-color: #27a9fb;
  color: #fff;
}
.bTagsGroup-item {
  padding-right: 15px;
  padding-left: 15px;
}
@media all and (max-width: 767px) {
  .bTagsGroup-item {
    padding-right: 8px;
    padding-left: 8px;
    min-width: 50%;
  }
}
.bTagsGroup.is-bg {
  background-color: #fff;
  border-radius: 100px;
  padding: 0 4px;
  font-size: 16px;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .bTagsGroup.is-bg {
    background: none;
    padding: 0;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 5px;
  }
}
.bTagsGroup.is-bg .bTagsGroup-item {
  padding: 0;
}
@media all and (max-width: 767px) {
  .bTagsGroup.is-bg .bTagsGroup-item {
    padding-right: 8px;
    padding-left: 8px;
  }
}
.bTagsGroup.is-bg .bTagsGroup-item:not(:last-child) {
  margin-right: 22px;
}
@media all and (max-width: 767px) {
  .bTagsGroup.is-bg .bTagsGroup-item:not(:last-child) {
    margin-right: 0;
  }
}
.bTagsGroup.is-bg .bTagsGroup-link {
  margin-top: 5px;
  margin-bottom: 5px;
}
@media all and (max-width: 767px) {
  .bTagsGroup.is-bg .bTagsGroup-link {
    margin-top: 0;
    margin-bottom: 25px;
  }
}
.bLoadMore.is-posts {
  margin-top: -55px;
}
.bLoadMore .ui-btn {
  border-radius: 10px;
  height: 50px;
}
.bPagination {
  font-size: 18px;
  font-family: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
.bPagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 42px;
  height: 42px;
  border-radius: 4px;
  margin: 0 6px;
}
.bPagination-item.is-current {
  background-color: #27a9fb;
  color: #fff;
  font-weight: bold;
}
.bPagination-item.is-dots {
  width: auto;
  margin: 0 26px;
}
.bPagination-item.is-prev,
.bPagination-item.is-next {
  fill: #273443;
}
.bPagination-item.is-prev .ico,
.bPagination-item.is-next .ico {
  width: 10px;
  height: 10px;
}
/*
	HEADER
	===============================================
*/
.header {
  padding: 20px 0;
  position: relative;
  z-index: 15;
}
@media all and (max-width: 767px) {
  .header {
    padding: 25px 0 0;
  }
}
.header.is-sticky {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}
.header-box {
  position: relative;
}
.header-item {
  position: relative;
  z-index: 10;
}
.header .bSocials {
  margin-left: 20px;
}
.header .bMainMenu {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 20px;
}
@media all and (max-width: 767px) {
  .header .bMainMenu {
    position: absolute;
    top: -30px;
    left: auto;
    right: -100%;
    height: 100vh;
    max-width: 334px;
    width: 100%;
    z-index: 8;
    opacity: 0;
    background-color: #fff;
    margin-top: 0;
    overflow-y: auto;
  }
}
.header .bMainMenu.is-active {
  right: -20px;
  opacity: 1;
}
.bHeaderLogo {
  padding: 8px 12px;
  background-color: rgba(255,255,255,0);
  border-radius: 8px;
  transition: all 0.32s linear;
}
@media all and (max-width: 767px) {
  .bHeaderLogo {
    border-radius: 50px;
  }
}
.bHeaderLogo.is-active {
  background-color: #fff;
}
.bHeaderLogo img {
  max-height: 60px;
}
@media all and (max-width: 767px) {
  .bHeaderLogo img {
    max-height: 50px;
  }
}
.bFormSearch {
  background-color: #fff;
  border-radius: 8px;
  max-width: 282px;
  position: relative;
  transition: all 0.32s linear;
}
.bFormSearch:hover {
  box-shadow: 0px 4px 10px rgba(210,222,236,0.5);
}
.bFormSearch-input {
  height: 36px;
  padding: 0 40px 0 16px;
  font-size: 12px;
  background-color: #fff;
  border-radius: 8px;
}
.bFormSearch .error-message {
  display: none;
}
.bFormSearch-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 26px;
  height: 26px;
  border-radius: 8px;
  background-color: #27a9fb;
  fill: #fff;
}
.bFormSearch-btn .ico {
  width: 12px;
  height: 12px;
}
.bFormSearch.is-process .bFormSearch-btn {
  position: absolute;
}
.autocompleteResult {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: -5px;
  width: 100%;
  padding: 15px 5px 5px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  font-size: 12px;
  line-height: 1.33;
  color: #96a8be;
}
.autocompleteResult:before {
  content: '';
  display: block;
  position: absolute;
  left: 5px;
  right: 5px;
  height: 1px;
  background-color: #96a8be;
  top: 5px;
  left: 0;
}
.autocompleteResult-item {
  padding: 5px 16px;
  cursor: pointer;
}
.autocompleteResult-item mark {
  color: #273443;
  background: none;
}
.bTogglerMenu {
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 6px;
  fill: #27a9fb;
}
.bTogglerMenu .ico {
  width: 22px;
  height: 22px;
}
.bMainMenu {
  display: block;
  background-color: #fff;
  border-radius: 40px;
  padding: 35px 0;
  overflow: hidden;
  transition: all 0.32s linear;
}
@media all and (max-width: 767px) {
  .bMainMenu {
    border-radius: 0;
    padding: 110px 40px 40px;
  }
}
.bMainMenu-item:not(:last-child) {
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .bMainMenu-item:not(:last-child) {
    margin-bottom: 60px;
  }
}
.bMainMenu-link {
  fill: #96a8be;
}
.bMainMenu-link span {
  display: inline-block;
  overflow: hidden;
  max-width: 0;
  opacity: 0;
  white-space: nowrap;
  transition: all 0.32s linear;
}
@media all and (max-width: 767px) {
  .bMainMenu-link span {
    max-width: none;
    opacity: 1;
  }
}
.bMainMenu-link:hover,
.bMainMenu-link.is-active {
  fill: #27a9fb;
  color: #27a9fb;
}
.bMainMenu .ui-icon {
  width: 22px;
  height: 22px;
  margin: 0 18px;
}
.bMainMenu:hover .bMainMenu-link span {
  max-width: none;
  opacity: 1;
  padding-right: 18px;
}
/*
  FOOTER
  ===============================================
*/
.footer {
  padding: 15px 0 48px;
}
.bFooterMenu-item:not(:last-child) {
  margin-right: 30px;
}
.bFooterSocials {
  margin-left: 50px;
}
/*
 * --- MODAL ------------------------------------
*/
.unimodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: -9999;
  background-color: rgba(153,159,169,0.4);
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: all 0.1s ease-out;
  padding: 1em;
}
.unimodal.is-active {
  z-index: 9999;
  opacity: 1;
  visibility: visible;
}
.unimodal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.unimodal-body {
  position: relative;
  z-index: 2;
  margin: auto;
  background-color: #fff;
  width: 100%;
  border-radius: 20px;
  padding: 34px 32px;
  max-width: 780px;
}
.unimodal.is-active .unimodal-body {
  animation: unimodal-show 0.2s ease-out 1 forwards;
}
.unimodal-close {
  width: 75px;
  height: 75px;
  fill: #27a9fb;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.unimodal-close .ico {
  width: 25px;
  height: 25px;
}
.bModal-title {
  color: #27a9fb;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .bModal-title {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
}
.bModalShare {
  height: 100vh;
  max-height: 620px;
}
.bModalShare-title {
  margin-bottom: 40px;
}
@media all and (max-width: 767px) {
  .bModalShare-title {
    margin-bottom: 30px;
  }
}
.bModalShare-top {
  max-width: 568px;
}
.bModalShare-bottom {
  max-width: 616px;
  margin-top: 100px;
}
@media all and (max-width: 767px) {
  .bModalShare-bottom {
    margin-top: 50px;
  }
}
.bShareLink {
  color: #27a9fb;
  font-family: 'SF Pro Display', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 200;
  line-height: 1;
}
.bShareLink p:not(:last-child) {
  margin-bottom: 16px;
}
.bShareField {
  background-color: #f0f4f7;
  border-radius: 5px;
  overflow: hidden;
  color: #3f496a;
  font-size: 20px;
  font-family: 'SF Pro Display', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 200;
  position: relative;
}
@media all and (max-width: 767px) {
  .bShareField {
    font-size: 16px;
  }
}
.bShareField-input {
  height: 50px;
  padding: 0 20px;
}
@media all and (max-width: 767px) {
  .bShareField-input {
    padding: 0 15px;
    height: 40px;
  }
}
.bShareField-btn {
  height: auto;
  width: 54px;
  background-color: #27a9fb;
  fill: #fff;
  border-radius: 5px;
}
@media all and (max-width: 767px) {
  .bShareField-btn {
    width: 42px;
  }
}
.bShareField-btn .ico {
  width: 23px;
  height: 23px;
}
@media all and (max-width: 767px) {
  .bShareField-btn .ico {
    width: 17px;
    height: 17px;
  }
}
.bShareField-msg {
  position: absolute;
  bottom: 1px;
  left: 2px;
  color: #27a9fb;
  font-size: 0.7em;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}
.bShareField.is-active .bShareField-msg {
  opacity: 1;
  visibility: visible;
}
.bModalShareSocials {
  color: #27a9fb;
}
.bModalShareSocials-title {
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
}
@media all and (max-width: 767px) {
  .bModalShareSocials-title {
    margin-bottom: 20px;
  }
}
.bModalShareSocials-title:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #949494;
  top: 50%;
  left: 0;
  margin-top: -1px;
  opacity: 0.5;
}
@media all and (max-width: 767px) {
  .bModalShareSocials-title:after {
    display: none;
  }
}
.bModalShareSocials-title span {
  display: inline-block;
  position: relative;
  background-color: #fff;
  padding: 0 15px;
  z-index: 2;
}
@media all and (max-width: 767px) {
  .bModalShareSocials .bSocials {
    margin-left: -12px;
    margin-right: -12px;
  }
}
.bModalShareSocials .bSocials-item:not(:last-child) {
  margin-right: 15px;
}
@media all and (max-width: 767px) {
  .bModalShareSocials .bSocials-item:not(:last-child) {
    margin-right: 10px;
  }
}
.bModalShareSocials .bSocials-link {
  width: 38px;
  height: 38px;
}
.bModalMsg {
  max-width: 870px;
}
@media all and (max-width: 767px) {
  .bFormMsg-bottom .bFormFilelist {
    margin-bottom: 15px;
  }
}
@-moz-keyframes unimodal-show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes unimodal-show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-o-keyframes unimodal-show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes unimodal-show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
/*
 * --- PARTS ------------------------------------
*/
.bFooterMsg {
  position: relative;
  z-index: 5;
}
.bFooterMsg-body {
  background-color: #e9f3ff;
  border-radius: 15px;
  max-width: 1058px;
  padding: 25px 0;
  padding-right: 74px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/bg-msg-full.png");
}
@media all and (max-width: 1279px) {
  .bFooterMsg-body {
    padding-right: 48px;
  }
}
@media all and (max-width: 1023px) {
  .bFooterMsg-body {
    background: none;
    padding: 0 0 44px;
    margin: 0;
  }
}
.bFooterMsg-body:before {
  content: '';
  display: block;
  position: absolute;
  top: -30px;
  bottom: -30px;
  left: 2.5%;
  width: 30%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/bg-msg.png");
}
@media all and (max-width: 1023px) {
  .bFooterMsg-body:before {
    display: none;
  }
}
.bFooterMsg-middle {
  margin-right: 50px;
}
@media all and (max-width: 1023px) {
  .bFooterMsg-middle {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.bFooterMsg-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.22;
}
.bFooterMsg-title b {
  color: #27a9fb;
}
.bFooterMsg-txt {
  line-height: 1.13;
  margin-top: 5px;
}
/*
 * --- PAGE Main --------------------------------
*/
.bHero {
  height: 100vh;
  max-height: 646px;
  background-color: #e9f3ff;
  border-radius: 0 0 200px 200px;
  overflow: hidden;
  margin-bottom: 100px;
  position: relative;
}
@media all and (max-width: 767px) {
  .bHero {
    border-radius: 0;
    max-height: 373px;
    margin-bottom: 26px;
  }
}
.bHero:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 38px;
  background-color: #f3f8fe;
  border-radius: 20px 20px 0 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: none;
}
@media all and (max-width: 767px) {
  .bHero:after {
    display: block;
  }
}
.bHero .swiper {
  height: 100%;
}
.bHero .bSlPagination {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: 5;
}
@media all and (max-width: 767px) {
  .bHero .bSlPagination {
    bottom: 64px;
  }
}
.bHeroItem {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0 0 200px 200px;
  padding: 0 10px;
}
@media all and (max-width: 767px) {
  .bHeroItem {
    border-radius: 0;
    padding-bottom: 76px;
    padding-top: 76px;
  }
}
.bHeroItem-box {
  position: relative;
  z-index: 2;
}
.bHeroItem-title {
  font-size: 35px;
  line-height: 1.29;
  font-weight: bold;
}
@media all and (max-width: 767px) {
  .bHeroItem-title {
    font-size: 20px;
  }
}
.bHeroItem-title b,
.bHeroItem-title strong {
  color: #27a9fb;
}
.bHeroItem-txt {
  font-weight: 100;
  font-size: 20px;
  line-height: 1.25;
  margin-top: 15px;
}
@media all and (max-width: 767px) {
  .bHeroItem-txt {
    font-size: 16px;
    margin-top: 10px;
  }
}
.bHeroItem-bottom {
  margin-top: 60px;
}
.bHeroItem.is-pic {
  color: #fff;
  position: relative;
}
.bHeroItem.is-pic:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,26,77,0.5);
  top: 0;
  left: 0;
  border-radius: 0 0 200px 200px;
}
@media all and (max-width: 767px) {
  .bHeroItem.is-pic:before {
    border-radius: 0;
  }
}
.bHeroItem.is-pic .bHeroItem-title b,
.bHeroItem.is-pic .bHeroItem-title strong {
  color: inherit;
}
.bHeroItem.is-pic .bHeroItem-txt {
  font-weight: 500;
}
.bCardPost-pic {
  height: 143px;
  background-color: #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}
@media all and (max-width: 767px) {
  .bCardPost-pic {
    margin-bottom: 10px;
  }
}
.bCardPost-date {
  color: #4e5d78;
  font-size: 12px;
  font-weight: 300;
  font-family: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  fill: #4e5d78;
  margin-bottom: 16px;
}
.bCardPost-date .ui-icon {
  width: 19px;
  height: 19px;
  margin-right: 10px;
}
.bCardPost-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.11;
  margin-bottom: 15px;
  color: #4e5d78;
}
@media all and (max-width: 767px) {
  .bCardPost-title {
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 13px;
    padding-right: 13px;
  }
}
.bCardPost-txt {
  font-weight: 100;
  line-height: 1;
}
@media all and (max-width: 767px) {
  .bCardPost-txt {
    font-size: 13px;
    padding-left: 13px;
    padding-right: 13px;
  }
}
.bCardPost-txt p:not(:last-child) {
  margin-bottom: 15px;
}
.bCardPost-bottom {
  margin-top: 40px;
}
@media all and (max-width: 767px) {
  .bCardPost-bottom {
    margin-top: 10px;
    padding-left: 13px;
    padding-right: 13px;
  }
}
.bCardPost .bDayNumber {
  max-width: 95%;
  margin-top: 42px;
  margin-bottom: 42px;
}
@media all and (max-width: 767px) {
  .bCardPost .bGraphLines {
    padding-left: 13px;
    padding-right: 13px;
    margin-bottom: 13px;
  }
}
.bCardPost.is-grid {
  padding: 15px;
}
.bCardPost.is-grid .bTag {
  font-size: 10px;
  min-width: 104px;
}
.bCardPost.is-grid .bCardLinkArrow {
  width: 23px;
  height: 23px;
}
.bCardPost.is-grid .bCardLinkArrow .ico {
  width: 12px;
  height: 12px;
}
.bCardPost.is-grid .bCardPost-pic {
  height: 120px;
  margin-bottom: 20px;
}
.bCardPost.is-grid .bCardPost-title {
  font-size: 15px;
  line-height: 1.2;
}
@media all and (max-width: 767px) {
  .bCardPost.is-grid .bCardPost-title {
    padding-left: 0;
    padding-right: 0;
  }
}
.bCardPost.is-grid .bCardPost-txt {
  font-size: 14px;
  line-height: 1.14;
}
@media all and (max-width: 767px) {
  .bCardPost.is-grid .bCardPost-txt {
    padding-left: 0;
    padding-right: 0;
  }
}
.bCardPost.is-grid .bCardPost-bottom {
  margin-top: 15px;
}
@media all and (max-width: 767px) {
  .bCardPost.is-grid .bCardPost-bottom {
    padding-left: 0;
    padding-right: 0;
  }
}
/*
 * --- Page News --------------------------------
*/
.bPostsGrid {
  margin-bottom: 20px;
}
/*
 * --- PAGE Posts -------------------------------
*/
.bPost {
  font-family: 'Kazimir', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #fff;
  border-radius: 20px;
  padding: 22px 32px 72px;
}
@media all and (max-width: 767px) {
  .bPost {
    border-radius: 10px;
    padding: 16px 16px 20px;
  }
}
.bPost-image {
  position: relative;
  margin-bottom: 30px;
}
.bPost-backLink,
.bPost-shareLink {
  position: absolute;
  top: 20px;
  z-index: 5;
}
@media all and (max-width: 767px) {
  .bPost-backLink,
  .bPost-shareLink {
    top: 15px;
  }
}
.bPost-backLink {
  left: 30px;
}
@media all and (max-width: 767px) {
  .bPost-backLink {
    left: 15px;
  }
}
.bPost-shareLink {
  right: 30px;
}
@media all and (max-width: 767px) {
  .bPost-shareLink {
    right: 15px;
  }
}
.bPost-pic {
  height: 365px;
  border-radius: 20px;
  overflow: hidden;
}
@media all and (max-width: 1023px) {
  .bPost-pic {
    height: 265px;
  }
}
@media all and (max-width: 767px) {
  .bPost-pic {
    border-radius: 10px;
    height: 120px;
  }
}
.bPost-header {
  margin-bottom: 30px;
  color: #27a9fb;
  font-size: 11px;
  line-height: 1.09;
}
.bPost-title {
  color: #273443;
  font-weight: 500;
  font-size: 25px;
  line-height: 1.2;
}
.bPost-theme {
  margin-bottom: 15px;
}
.bPost-date {
  margin-top: 15px;
}
.bPost-txt {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.14;
}
@media all and (max-width: 767px) {
  .bPost-txt {
    font-size: 16px;
  }
}
.bPost-txt p:not(:last-child) {
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .bPost-txt p:not(:last-child) {
    margin-bottom: 15px;
  }
}
.bPostGraph {
  max-width: 800px;
  margin: auto;
}
.bPostGraph:not(:last-child) {
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .bPostGraph:not(:last-child) {
    margin-bottom: 15px;
  }
}
.bPostGraph-title {
  color: #27a9fb;
  font-weight: 500;
  margin-bottom: 30px;
  font-size: 20px;
}
@media all and (max-width: 767px) {
  .bPostGraph-title {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.bPostGraph-month {
  border-radius: 6px;
  background-color: #f4f7fe;
  fill: #a3aed0;
  color: #a3aed0;
  font-family: 'DM Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  padding: 10px 24px;
}
@media all and (max-width: 767px) {
  .bPostGraph-month {
    font-size: 8px;
    padding: 6px 15px;
  }
}
.bPostGraph-month .ui-icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
@media all and (max-width: 767px) {
  .bPostGraph-month .ui-icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
}
.bPostGraph-number {
  font-family: 'DM Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #a3aed0;
  line-height: 1.75;
  padding-left: 2px;
}
@media all and (max-width: 767px) {
  .bPostGraph-number {
    font-size: 10px;
  }
}
.bPostGraph-number:not(:first-child) {
  margin-top: 23px;
}
@media all and (max-width: 767px) {
  .bPostGraph-number:not(:first-child) {
    margin-top: 10px;
  }
}
.bPostGraph-number p:not(:last-child) {
  margin-bottom: -4px;
}
.bPostGraph-number strong {
  font-weight: bold;
  font-size: 30px;
  line-height: 1.27;
  color: #2b3674;
}
@media all and (max-width: 767px) {
  .bPostGraph-number strong {
    font-size: 22px;
  }
}
.bPostGraph-number i {
  font-style: normal;
  color: #05cd99;
  font-size: 10px;
  font-weight: bold;
  margin-left: 10px;
}
@media all and (max-width: 767px) {
  .bPostGraph-number i {
    display: block;
  }
}
.bPostGraph-row {
  max-width: 620px;
}
.bPostGraph-row canvas {
  height: auto;
  min-height: 160px;
}
@media all and (max-width: 767px) {
  .bPostGraph-row canvas {
    min-height: auto;
  }
}
.bPostGraph-canvas {
  max-width: 648px;
}
.bPostGraph-canvas canvas {
  max-height: 245px;
}
/*
 * --- PAGE About -------------------------------
*/
.bAboutTitle {
  color: #27a9fb;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 30px;
}
.bAboutContent {
  font-size: 20px;
  line-height: 1.25;
  font-weight: 100;
}
.bAboutContent p:not(:last-child) {
  margin-bottom: 15px;
}
.bAboutContent hr {
  display: block;
  margin: 0;
  height: 15px;
  border: 0;
}
.bAboutContent h1,
.bAboutContent h2,
.bAboutContent h3,
.bAboutContent h4 {
  color: #27a9fb;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.25;
}
.bAboutContent h1:not(:last-child),
.bAboutContent h2:not(:last-child),
.bAboutContent h3:not(:last-child),
.bAboutContent h4:not(:last-child) {
  margin-bottom: 30px;
}
.bAboutContent h1:not(:first-child),
.bAboutContent h2:not(:first-child),
.bAboutContent h3:not(:first-child),
.bAboutContent h4:not(:first-child) {
  margin-top: 30px;
}
.bCardPerson {
  padding: 13px 15px;
}
@media all and (max-width: 767px) {
  .bCardPerson {
    padding: 16px 20px;
  }
}
.bCardPerson-type {
  background-color: #96a8be;
  color: #fff;
  border-radius: 100px;
  padding: 6px 20px;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  .bCardPerson-type {
    font-size: 14px;
    margin-bottom: 28px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.bCardPerson-pic {
  width: 113px;
  height: 113px;
  border: 2px solid #27a9fb;
  margin-bottom: 15px;
  border-radius: 300px;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  .bCardPerson-pic {
    width: 144px;
    height: 144px;
    margin-bottom: 20px;
  }
}
.bCardPerson-txt {
  line-height: 1.13;
  font-weight: 500;
  color: #27a9fb;
  margin-bottom: 5px;
}
@media all and (max-width: 767px) {
  .bCardPerson-txt {
    font-size: 20px;
    line-height: 1.15;
  }
}
.bCardPerson-txt span {
  color: #96a8be;
}
.bCardPerson-bottom {
  border-top: 1px solid rgba(150,168,190,0.5);
  margin-top: 15px;
  padding-top: 15px;
  line-height: 1.13;
  font-weight: 500;
  color: #96a8be;
}
@media all and (max-width: 767px) {
  .bCardPerson-bottom {
    font-size: 20px;
    line-height: 1.15;
  }
}
@media all and (max-width: 767px) {
  .bContacts {
    font-size: 20px;
    line-height: 1.25;
  }
}
.bContacts-txt {
  font-weight: 500;
  line-height: 1.13;
  color: #96a8be;
}
.bContacts-txt p:not(:last-child) {
  margin-bottom: 15px;
}
.bContacts-map {
  margin-top: 50px;
}
@media all and (max-width: 767px) {
  .bContacts-map {
    margin-top: 30px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.bMap {
  height: 435px;
  background-color: #ccc;
  max-width: 1187px;
  position: relative;
}
@media all and (max-width: 767px) {
  .bMap {
    height: 90vh;
  }
}
.bMap [class*=ymaps-2][class*="-ground-pane"] {
  filter: grayscale(100%);
}
.bMap-balloon {
  font-size: 10px;
  font-family: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  line-height: normal;
}
.bMap-balloon p:not(:last-child) {
  margin-bottom: 15px;
}
.bMap-balloon span {
  color: #a4a4a4;
}
.bMap .bMapZoom {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  right: 36px;
}
@media all and (max-width: 767px) {
  .bMap .bMapZoom {
    right: 24px;
  }
}
.bMapZoom {
  border-radius: 30px;
  width: 28px;
  background-color: #27a9fb;
  position: relative;
}
.bMapZoom-btn {
  width: 100%;
  height: 32px;
  fill: #fff;
}
.bMapZoom-btn .ico {
  width: 10px;
  height: 10px;
}
.bMapZoom-btn:not(:last-child) {
  border-bottom: 1px solid #fff;
}
/*
 * --- PAGE Rate --------------------------------
*/
.bWidgetGroup {
  margin-bottom: 15px;
}
.bWidgetGroup-item {
  padding-bottom: 15px;
}
