/*!
 * --- MODAL ------------------------------------
*/
.unimodal
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	z-index: -9999;
	background-color: rgba(#999FA9, 0.4);
	opacity: 0;
	visibility: hidden;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	transition: all 0.1s ease-out;
	padding: 1em;

	&.is-active
		z-index: 9999;
		opacity: 1;
		visibility: visible;

	&-overlay
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

	&-body
		position: relative;
		z-index: 2;
		margin: auto;
		background-color: #fff;
		width: 100%;
		border-radius: 20px;
		padding: 34px 32px;
		max-width: 780px;

	&.is-active &-body
		animation: unimodal-show 0.2s ease-out 1 forwards;

	&-close
		size(75px,75px);
		fill: $colorAccent;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;

		.ico
			size(25px,25px);


@keyframes unimodal-show
	from
		transform: scale(0)
	to
		transform: scale(1)


.bModal
	&-title
		color: $colorAccent;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 30px;
		@media phone
			font-size: 20px;
			line-height: round(30/20,2);
			margin-bottom: 15px;


.bModalShare
	height: 100vh;
	max-height: 620px;

	&-title
		margin-bottom: 40px;
		@media phone
			margin-bottom: 30px;

	&-top
		max-width: 568px;

	&-bottom
		max-width: 616px;
		margin-top: 100px;
		@media phone
			margin-top: 50px;


.bShareLink
	color: $colorAccent;
	font-family: $fontSF;
	font-weight: 200;
	line-height: 1;

	p:not(:last-child)
		margin-bottom: 16px;

.bShareField
	background-color: #F0F4F7;
	border-radius: 5px;
	overflow: hidden;
	color: #3F496A;
	font-size: 20px;
	font-family: $fontSF;
	font-weight: 200;
	position: relative;
	@media phone
		font-size: 16px;

	&-input
		height: 50px;
		padding: 0 20px;
		@media phone
			padding: 0 15px;
			height: 40px;

	&-btn
		height: auto;
		width: 54px;
		background-color: $colorAccent;
		fill: #fff;
		border-radius: 5px;
		@media phone
			width: 42px;

		.ico
			size(23px,23px);
			@media phone
				size(17px,17px);

	&-msg
		position: absolute;
		bottom: 1px;
		left: 2px;
		color: $colorAccent;
		font-size: 0.7em;
		z-index: 2;
		opacity: 0;
		visibility: hidden;

	&.is-active &-msg
		opacity: 1;
		visibility: visible;


.bModalShareSocials
	color: $colorAccent;

	&-title
		margin-bottom: 25px;
		overflow: hidden;
		position: relative;
		@media phone
			margin-bottom: 20px;

		&:after
			pseudo();
			width: 100%;
			height: 1px;
			background-color: #949494;
			top: 50%;
			left: 0;
			margin-top: -1px;
			opacity: 0.5;
			@media phone
				display: none;

		span
			display: inline-block;
			position: relative;
			background-color: #fff;
			padding: 0 15px;
			z-index: 2;

	.bSocials
		@media phone
			margin-left: -12px;
			margin-right: -12px;

		&-item
			&:not(:last-child)
				margin-right: 15px;
				@media phone
					margin-right: 10px;

		&-link
			size(38px,38px);


.bModalMsg
	max-width: 870px;


.bFormMsg
	&-bottom .bFormFilelist
		@media phone
			margin-bottom: 15px;
