/*!
  FOOTER
  ===============================================
*/

.footer
	padding: 15px 0 48px;

.bFooterMenu
	&-item
		&:not(:last-child)
			margin-right: 30px;


.bFooterSocials
	margin-left: 50px;
