/*!
 * --- PAGE Main --------------------------------
*/
.bHero
	height: 100vh;
	max-height: 646px;
	background-color: #E9F3FF;
	border-radius: 0 0 200px 200px;
	overflow: hidden;
	margin-bottom: 100px;
	position: relative;
	@media phone
		border-radius: 0;
		max-height: 373px;
		margin-bottom: 26px;

	&:after
		pseudo();
		width: 100%;
		height: 38px;
		background-color: #F3F8FE;
		border-radius: 20px 20px 0 0;
		bottom: 0;
		left: 0;
		z-index: 5;
		display: none;
		@media phone
			display: block;

	.swiper
		height: 100%;

	.bSlPagination
		position: absolute;
		bottom: 50px;
		left: 0;
		z-index: 5;
		@media phone
			bottom: 64px;


.bHeroItem
	bg(center,cover);
	border-radius: 0 0 200px 200px;
	padding: 0 10px;
	@media phone
		border-radius: 0;
		padding-bottom: 76px;
		padding-top: 76px;

	&-box
		position: relative;
		z-index: 2;

	&-title
		font-size: 35px;
		line-height: round(45/35,2);
		font-weight: bold;
		@media phone
			font-size: 20px;

		b, strong
			color: $colorAccent;

	&-txt
		font-weight: 100;
		font-size: 20px;
		line-height: round(25/20,2);
		margin-top: 15px;
		@media phone
			font-size: 16px;
			margin-top: 10px;

	&-bottom
		margin-top: 60px;

	&.is-pic
		color: #fff;
		position: relative;

		&:before
			pseudo();
			width: 100%;
			height: 100%;
			background-color: rgba(#001A4D,0.5);
			top: 0;
			left: 0;
			border-radius: 0 0 200px 200px;
			@media phone
				border-radius: 0;

	&.is-pic &
		&-title
			b, strong
				color: inherit;

		&-txt
			font-weight: 500;



.bCardPost
	&-pic
		height: 143px;
		background-color: #ccc;
		border-radius: 10px;
		overflow: hidden;
		margin-bottom: 15px;
		@media phone
			margin-bottom: 10px;

	&-date
		color: $colorTitle;
		font-size: 12px;
		font-weight: 300;
		font-family: $fontMont;
		fill: $colorTitle;
		margin-bottom: 16px;

		.ui-icon
			size(19px,19px);
			margin-right: 10px;

	&-title
		font-weight: 500;
		font-size: 18px;
		line-height: round(20/18,2);
		margin-bottom: 15px;
		color: $colorTitle;
		@media phone
			font-size: 14px;
			margin-bottom: 10px;
			padding-left: 13px;
			padding-right: 13px;

	&-txt
		font-weight: 100;
		line-height: 1;
		@media phone
			font-size: 13px;
			padding-left: 13px;
			padding-right: 13px;

		p:not(:last-child)
			margin-bottom: 15px;

	&-bottom
		margin-top: 40px;
		@media phone
			margin-top: 10px;
			padding-left: 13px;
			padding-right: 13px;

	.bDayNumber
		max-width: 95%;
		margin-top: 42px;
		margin-bottom: 42px;

	.bGraphLines
		@media phone
			padding-left: 13px;
			padding-right: 13px;
			margin-bottom: 13px;

	&.is-grid
		padding: 15px;

		.bTag
			font-size: 10px;
			min-width: 104px;

		.bCardLinkArrow
			size(23px,23px);

			.ico
				size(12px,12px);

	&.is-grid &
		&-pic
			height: 120px;
			margin-bottom: 20px;

		&-title
			font-size: 15px;
			line-height: round(18/15,2);
			@media phone
				padding-left: 0;
				padding-right: 0;

		&-txt
			font-size: 14px;
			line-height: round(16/14,2);
			@media phone
				padding-left: 0;
				padding-right: 0;

		&-bottom
			margin-top: 15px;
			@media phone
				padding-left: 0;
				padding-right: 0;
