/*!
 * --- PAGE About -------------------------------
*/
.bAboutTitle
	color: $colorAccent;
	font-size: 20px;
	font-weight: 500;
	line-height: round(25/20,2);
	margin-bottom: 30px;


.bAboutContent
	font-size: 20px;
	line-height: round(25/20,2);
	font-weight: 100;

	p:not(:last-child)
		margin-bottom: 15px;

	hr
		display: block;
		margin: 0;
		height: 15px;
		border: 0;

	h1, h2, h3, h4
		color: $colorAccent;
		font-size: 20px;
		font-weight: 500;
		line-height: round(25/20,2);

		&:not(:last-child)
			margin-bottom: 30px;

		&:not(:first-child)
			margin-top: 30px;


.bCardPerson
	padding: 13px 15px;
	@media phone
		padding: 16px 20px;

	&-type
		background-color: #96A8BE;
		color: #fff;
		border-radius: 100px;
		padding: 6px 20px;
		font-size: 11px;
		font-weight: 500;
		margin-bottom: 20px;
		@media phone
			font-size: 14px;
			margin-bottom: 28px;
			padding-top: 8px;
			padding-bottom: 8px;

	&-pic
		size(113px,113px);
		border: 2px solid $colorAccent;
		margin-bottom: 15px;
		border-radius: 300px;
		overflow: hidden;
		@media phone
			size(144px,144px);
			margin-bottom: 20px;

	&-txt
		line-height: round(18/16,2);
		font-weight: 500;
		color: $colorAccent;
		margin-bottom: 5px;
		@media phone
			font-size: 20px;
			line-height: round(23/20,2);

		span
			color: #96A8BE;

	&-bottom
		border-top: 1px solid rgba(#96A8BE,0.5);
		margin-top: 15px;
		padding-top: 15px;
		line-height: round(18/16,2);
		font-weight: 500;
		color: #96A8BE;
		@media phone
			font-size: 20px;
			line-height: round(23/20,2);


.bContacts
	@media phone
		font-size: 20px;
		line-height: round(25/20,2);

	&-txt
		font-weight: 500;
		line-height: round(18/16,2);
		color: #96A8BE;

		p:not(:last-child)
			margin-bottom: 15px;

	&-map
		margin-top: 50px;
		@media phone
			margin-top: 30px;
			margin-left: -20px;
			margin-right: -20px;


.bMap
	height: 435px;
	background-color: #ccc;
	max-width: 1187px;
	position: relative;
	@media phone
		height: 90vh;

	[class*=ymaps-2][class*="-ground-pane"]
		filter: grayscale(100%);

	&-balloon
		font-size: 10px;
		font-family: $fontRoboto;
		line-height: normal;

		p:not(:last-child)
			margin-bottom: 15px;

		span
			color: #A4A4A4;

	.bMapZoom
		position: absolute;
		z-index: 10;
		top: 50%;
		transform: translateY(-50%);
		right: 36px;
		@media phone
			right: 24px;


.bMapZoom
	border-radius: 30px;
	width: 28px;
	background-color: $colorAccent;
	position: relative;

	&-btn
		size(100%,32px);
		fill: #fff;

		.ico
			size(10px,10px);

		&:not(:last-child)
			border-bottom: 1px solid #fff;
